<template>
  <div class="masonry-collection">
    <div ref="gutter_sizer" class="gutter-sizer"></div>
    <div v-if="$slots.static_item_before" class="masonry-item static-item"
         :style="masonryBrickStyles"
    >
      <slot name="static_item_before"/>
    </div>
    <div class="masonry-item"
         v-for="(item) in items"
         :key="item.id"
         :style="masonryBrickStyles"
    >
      <slot
          :item="item"
          :reLayoutMasonry="reLayoutMasonry"
      />
    </div>
  </div>
</template>

<script>

import Bs4Grid from "@/mixins/bs4-grid";
import Masonry from "masonry-layout";

export default {
  name: "MasonryCollection",
  mixins: [Bs4Grid],
  props: {
    items: {type: Array, default: () => []},
  },
  watch: {
    items(nv, ov) {
      if (this.msnry) {
        console.log('watch => relayout')
        this.msnry.reloadItems();
        this.msnry.layout()
          window.setTimeout(() => { //TODO: find better workaround
            if (this.msnry) {
              this.msnry.reloadItems();
              this.msnry.layout()
            }
          }, 500)
        if (nv.length !== ov.length) {
          window.setTimeout(() => { //TODO: find better workaround
            if (this.msnry) {
              this.msnry.reloadItems();
              this.msnry.layout()
            }
          }, 1500)
          window.setTimeout(() => { //TODO: find better workaround
            if (this.msnry) {
              this.msnry.reloadItems();
              this.msnry.layout()
            }
          }, 3000)
        }
      }
    }
  },
  data() {
    return {
      gutter: 30,
    }
  },
  computed: {
    masonryBrickStyles() {
      let numberOfCols = 6
      switch (this.gridBp) {
        case "xxs":
          numberOfCols = 1;
          break;
        case "xs":
        case "sm":
          numberOfCols = 2;
          break;
        case "md":
          numberOfCols = 3;
          break;
        case "lg":
          numberOfCols = 4;
          break;
        case "xl":
          numberOfCols = 5;
          break;
      }
      let numberOfGutters = numberOfCols - 1
      let gutterSummaryInPx = numberOfGutters * (this.$refs.gutter_sizer ? this.$refs.gutter_sizer.clientWidth : 10)
      return {
        width: `calc((100% - ${gutterSummaryInPx}px)/ ${numberOfCols})`
      }
    }
  },
  mounted() {

    this.$nextTick(() => {
      this.msnry = new Masonry(this.$el, {
        // columnWidth: "200px",
        itemSelector: '.masonry-item',
        gutter: '.gutter-sizer',
        // options...
      });
      // this.$forceUpdate();
      window.setTimeout(() => { //TODO: find better workaround
        if (this.msnry) {
          console.log('after mounted => relayout')
          this.msnry.reloadItems();
          this.msnry.layout()
        }
      }, 1000)

    })
  },
  beforeDestroy() {
    this.msnry.destroy()
  },
  methods: {
    reLayoutMasonry() {
      if (this.msnry) {
        this.msnry.layout()
      }
    }
  }
}
</script>
