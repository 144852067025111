<template>
  <div class="statistics-chart">
    <component
        v-if="statistics?.datasets"
        :is="chartType"
        :chart-options="chartOptions"
        :chart-data="chartData"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
        :height="height"
    />
    <ps-accordion-collapse class="border">
      <template #header="{active}">
        <div class="d-flex justify-content-between p-2">
          {{ $t('chart.table', { name: chartConfig.name }) }}
          <i class="fas fa-chevron-left" :class="{active}" aria-hidden="true"></i>
        </div>
      </template>
      <template #content>
        <div class="table-responsive">
          <table class="table table-striped table-bordered">
            <thead>

            <tr>
              <th scope="col">{{ datasetIdKey }}</th>
              <th scope="col" v-for="set in chartConfig.config.datasets">
                {{ set.label }}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(label, idx) in statistics.labels">
              <th scope="row">{{ label }}</th>
              <td v-for="set in statistics.datasets">
                {{ set.data[idx] }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </template>
    </ps-accordion-collapse>
  </div>
</template>

<script>

import { v1 } from 'uuid'
import { Bar } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import PsAccordionCollapse from 'pixelstein-vue-app-package/src/vue2/PsAccordion/PsAccordionCollapse'
import _cloneDeep from 'lodash/cloneDeep'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

dayjs.extend(advancedFormat)

export default {
  name: 'CachedStatisticsChart',
  components: {
    PsAccordionCollapse,
    Bar,
    // , Pie, Bubble, Doughnut, Line, PolarArea, Radar, Scatter
  },
  props: {
    statistics: { type: Object, required: true },
    chartConfig: { type: Object, default: () => ({}) },
    datasetIdKey: { type: String, default: 'label' },
    chartType: { type: String, default: 'bar' },
    width: { type: Number, default: 1700 },
    height: { type: Number, default: 800 },
    cssClasses: { default: '', type: String },
    styles: { type: Object, default: () => ({}) },
    plugins: { type: Array, default: () => [] },
    reportFields: { type: Array, default: () => [] },
  },
  data () {
    return {
      chartId: v1(),
      chart: null,
    }
  },
  computed: {
    chartData() {
      const data = _cloneDeep(this.statistics)

      for (let i=0, len=data.datasets.length; i<len; ++i) {
        data.datasets[i] = {
          backgroundColor: this.chartConfig.config.datasets[i].color,
          ...data.datasets[i]
        }
      }

      return data
    },
    chartOptions () {
      return {
        legend: {
          display: this.chartType === 'pie',
        },
        scales: {
          y: {
            display: this.chartType === 'bar',
            beginAtZero: true,
            ticks: {
              min: 0,
              suggestedMax: 8,
              precision: 0,
            },
          },
        },
        responsive: true,
      }
    },
  },
}
</script>
