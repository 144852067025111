<template>
  <div class="statistics-module view">
    <search-bar class="my-3"
                :placeholder="$t('reports.search_in_reports')"
                :debounceWait="700"
                v-model="searchQuery"
    />

    <template v-if="!loading">
      <multi-layout-list :items="reportConfigs"
                         az-sort-key="name"
                         date-sort-key="modified"
                         offset-to=".view"
                         :initial-scroll-pos="scrollPos"
                         :initial-page="page"
                         @scroll-to-end="page++"
      >
        <template #headerToolbar>

        </template>
        <template #cardTemplate="{item}">
          <general-card
              :default-image="statisticThumbnail"
              :title="item.name"
              @click="handleItemOption({value:'view'}, item)"
              class="mb-3"
          >
            <div @click.stop>
              <drop-down class="ml-3"
                         :options="itemOptions"
                         :drop-up="true"
                         drop-left
                         close-on-choose
                         @click="handleItemOption($event, item)"
              >
                <svg-icon name="ellipsis"/>
              </drop-down>
            </div>
          </general-card>
        </template>
        <template #listItemTemplate="{item}">
          <general-list-item
              :default-image="statisticThumbnail"
              :title="item.name"
              @click="handleItemOption({value:'view'}, item)"
          >
            <div @click.stop>
              <drop-down class="ml-3"
                         :options="itemOptions"
                         :drop-up="false"
                         drop-left
                         close-on-choose
                         @click="handleItemOption($event, item)"
              >
                <svg-icon name="ellipsis"/>
              </drop-down>
            </div>
          </general-list-item>
        </template>
      </multi-layout-list>
    </template>
    <loading-screen v-else />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import SearchBar from '@/components/SearchBar'
import SvgIcon from '@/components/SvgIcon'
import DropDown from '@/components/DropDown'
import MultiLayoutList from '@/components/MultiLayoutList'
import GeneralCard from '@/components/GeneralCard'
import GeneralListItem from '@/components/GeneralListItem'
import statisticThumbnail from '@/assets/defaultThumbnails/statistic.svg'
import LoadingScreen from '@/components/LoadingScreen.vue'

export default {
  name: 'ReportModule',
  components: {
    MultiLayoutList,
    DropDown,
    SvgIcon,
    SearchBar,
    GeneralCard,
    GeneralListItem,
    LoadingScreen
  },
  data () {
    return {
      searchQuery: '',
      lastSearchQuery: '',
      loading: false,
      page: 1,
      itemsPerPage: 20,
      scrollPos: 0,
      statisticThumbnail,
    }
  },
  computed: {
    ...mapState({
      allReportConfigs: state => state.Api.ReportConfigs.all,
    }),
    reportConfigs () {
      if (this.searchQuery !== this.lastSearchQuery) {
        this.loading = true
      }
      const regex = new RegExp(this.searchQuery, 'i')
      this.lastSearchQuery = this.searchQuery;

      const filteredReportConfigs = this.allReportConfigs.filter(con => con.campaign?.match(regex) || con.name.match(regex))
      setTimeout(() => this.loading = false, 1000)
      return filteredReportConfigs
    },
    itemOptions () {
      return [
        {
          group: 'default',
          groupLabel: 'default',
          value: 'view',
          label: this.$t('statistics.show_statistics'),
          active: false,
        },
      ]
    },
  },
  methods: {
    ...mapActions({
      addReportConfig: 'Api/ReportConfigs/add',
      editReportConfig: 'Api/ReportConfigs/edit',
      deleteReportConfig: 'Api/ReportConfigs/delete',
    }),
    handleItemOption (action, reportConfig) {
      switch (action.value) {
        case 'view':
          this.$router.push(['/statistics', reportConfig.id].join('/')).catch(() => null)
          break
      }
    },
  },
}
</script>

