<template>
  <router-link :to="to" class="d-inline-block h3 py-2 text-dark">
    <i class="fas fa-chevron-left" aria-hidden="true"></i>
    <slot/>
  </router-link>
</template>

<script>
export default {
  name: "RouterLinkBack",
  props: {
    to: {type: String, default: '/'}
  }
}
</script>

<style scoped>

</style>
