'use strict'

export function collectDateKeys (contents, resultArray = []) {
  resultArray = resultArray.concat(contents
    .filter(c => c?.options?.type === 'datetime-local')
    .map(c => c?.options?.name || c?.options?.key))
  
  contents
    .filter(c => c?.options?.contents)
    .forEach(c => {
      resultArray = collectDateKeys(c?.options?.contents, resultArray)
    })
  
  return resultArray
}
