<template>
  <div class="help-module view scroll pb-3">
    <div class="sticky-top title bg-white d-flex justify-content-between py-3 px-3 pb-1">
      <h2 class="h4">{{ $t('help.title') }}</h2>
      <button v-if="currentMenu.length !== 0" type="button" @click="openMenu([])" aria-label="Close" class="close">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <div v-if="!currentMenu.meta" class="row justify-content-around">
      <div v-for="menuItem in menu" class="col-12 col-lg-6 col-xl-4">
        <div class="my-2 p-2 card"  @click="openMenu(menuItem)">
          <span class="header m-3 p-0 nav-link">
            <svg-icon :name="menuItem.meta.menu.icon"/>
            <span class="h4 mx-2" v-html="$t(menuItem.meta.menu.title)"></span>
          </span>
          <div class="mt-0 mx-3">
            <span v-html="$t(menuItem.meta.menu.text)"></span>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="row">
      <div class="col-12 col-lg-3 bg-white sticky-top help-navi">
        <button class="btn burger-btn w-100 p-0 d-lg-none" @click="openNavi = !openNavi">
          <svg-icon class="burger-menu-icon" name="burger_menu"/>
        </button>
        <div class="navi" :class="{active: openNavi}">
          <div v-for="menuItem in menu">
          <span :class="{active: currentMenu === menuItem}" class="nav-link" @click="openMenu(menuItem)">
            <svg-icon name="arrow_right"/>
            <span class="mx-2 underline" v-html="$t(menuItem.meta.menu.title)"></span>
          </span>
          </div>
        </div>
      </div>
      <div class="col-12 py-3 py-lg-0 col-lg-9">
        <span class="header mb-5">
          <svg-icon :name="currentMenu.meta.menu.icon"/>
          <span class="h4 mx-2" v-html="$t(currentMenu.meta.menu.title)"></span>
        </span>
        <div>
          <files-help v-if="currentMenu.meta.menu.component === 'FilesHelp'"/>
          <settings-help v-if="currentMenu.meta.menu.component === 'SettingsHelp'"/>
          <statistics-help v-if="currentMenu.meta.menu.component === 'StatisticsHelp'"/>
          <device-management-help v-if="currentMenu.meta.menu.component === 'DeviceManagementHelp'"/>
          <reports-help v-if="currentMenu.meta.menu.component === 'ReportsHelp'"/>
          <dashboards-help v-if="currentMenu.meta.menu.component === 'DashboardsHelp'"/>
          <message-center-help v-if="currentMenu.meta.menu.component === 'MessageCenterHelp'"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon.vue'
import FilesHelp from '@/components/helpModule/FilesHelp.vue'
import DashboardsHelp from '@/components/helpModule/DashboardsHelp.vue'
import DeviceManagementHelp from '@/components/helpModule/DeviceManagementHelp.vue'
import MessageCenterHelp from '@/components/helpModule/MessageCenterHelp.vue'
import ReportsHelp from '@/components/helpModule/ReportsHelp.vue'
import SettingsHelp from '@/components/helpModule/SettingsHelp.vue'
import StatisticsHelp from '@/components/helpModule/StatisticsHelp.vue'

export default {
  name: 'HelpModule',
  components: {
    MessageCenterHelp,
    DashboardsHelp,
    ReportsHelp,
    DeviceManagementHelp,
    StatisticsHelp,
    SettingsHelp,
    FilesHelp,
    SvgIcon,
  },
  data () {
    return {
      currentMenu: [],
      openNavi: false
    }
  },
  computed: {
    menu () {
      return this.$router.getRoutes()
          .filter(route => route?.meta?.menu)
          .filter(route => route?.name !== this.$route.name)
          .toSorted((a, b) => a.meta.menu.position - b.meta.menu.position)
    },
  },
  methods: {
    openMenu(menuItem) {
      window.localStorage.setItem('helpMenu', menuItem.name)
      this.currentMenu = menuItem
    }
  },
  mounted () {
    const savedMenu = this.menu.find(m => m.name === window.localStorage.getItem('helpMenu'))
    if (savedMenu) {
      this.openMenu(savedMenu)
    }
  }
}
</script>
