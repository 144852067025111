<template>
  <div class="report-list view">
    <div class="sticky-top bg-white row pb-1 pt-2">
      <div class="col-12">
        <search-bar
            class="my-2"
            :placeholder="$t('report_list.search')"
            :debounceWait="700"
            v-model="searchQuery"
        />
      </div>
    </div>

    <multi-layout-list
        class="mt-3"
        :items="tagFilteredReports"
        unique-key="local_id"
        :list-key="reportConfigId"
        :az-sort-key="azSortKey"
        :date-sort-key="dateSortKey"
        offset-to=".view"
        :initial-scroll-pos="scrollPos"
        :initial-page="page"
        @scroll-to-end="page++"
        :sortingOptions="sortingOptions"
    >

      <template #headerToolbar>
        <div class="d-flex align-items-center justify-content-between">
          <router-link-back to="/reports">
            {{ reportConfig?.name }}
          </router-link-back>
          <div>
            <router-link
                class="btn btn-sm btn-secondary"
                :to="{name: 'Edit Report Config', params: {reportConfigId: reportConfigId}}"
                v-if="canEditReportConfigs"
            >
              {{ $t('reports.edit_config') }}
            </router-link>
            &nbsp;
            <router-link
                class="btn btn-sm btn-secondary"
                :to="{name: 'New Report', params: {reportConfigId: reportConfigId, reportId: 'new', mode: 'new'}}"
                v-if="canCreateReports"
            >
              {{ $t('reports.new_report', { name: reportConfig?.name }) }}
            </router-link>
          </div>
        </div>
        <form-radio class="text"
                    :options="labeledReportStates"
                    v-model="activeReportState"
                    @input="updateLocalStorage()"
        />
        <div class="report-filters row mt-2">
          <div
              class="col"
              v-for="(listColumn, index) in listColumns"
              :key="'filter_' + index"
              v-if="!isDateColumn(listColumn)"
          >
            <select
                class="form-control ps-form-select"
                @change="updateFilter($event.target.value, listColumn)"
            >
              <option :selected="!isSelected(listColumn)" value="">
                {{ listColumn.label }}
              </option>
              <option :selected="isSelected(listColumn) === value" v-for="value in columnValues(listColumn)">
                {{ value }}
              </option>
            </select>
          </div>
        </div>
      </template>

      <template #listItemTemplate="{item}">
        <router-link :to="getReportRouteParameters(item)" class="form-row bg-extra-light py-5 px-3 mb-3 text-dark">
          <div class="col-8">
            <div class="row">
              <div v-for="column in listColumns"
                   :key="column.key"
                   :class="column.class"
              >
                <strong>{{ column.label }}: </strong> {{
                  formatListColumn(item, column)
                }}
              </div>
            </div>
          </div>

          <div class="col-3">
            <strong>{{ $t('report_list.state') }}: </strong> {{ item.state || '-' }}
          </div>

          <div @click.stop class="col-1 text-right">
            <drop-down class="ml-3"
                       :options="itemOptions"
                       :drop-up="false"
                       drop-left
                       close-on-choose
                       @click="handleItemOption($event, item)"
            >
              <svg-icon name="ellipsis"/>
            </drop-down>
          </div>
        </router-link>
      </template>

      <template #cardTemplate="{item}">
        <router-link :to="getReportRouteParameters(item)" class="bg-extra-light p-5 mb-3 text-dark d-block">
          <div v-for="column in listColumns"
               :key="column.key"
          >
            <strong>{{ column.label }}: </strong> {{
              formatListColumn(item, column)
            }}
          </div>
          <strong>{{ $t('report_list.state') }}: </strong> {{ item.state || '-' }}
          <div @click.stop class="text-right">
            <drop-down class="ml-3"
                       :options="itemOptions"
                       :drop-up="true"
                       drop-left
                       close-on-choose
                       @click="handleItemOption($event, item)"
            >
              <svg-icon name="ellipsis"/>
            </drop-down>
          </div>
        </router-link>
      </template>
    </multi-layout-list>

    <div class="loading-modal" v-if="loading">
      <loading-screen/>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import dayjs from 'dayjs'
import SvgIcon from 'pixelstein-vue-app-package/src/vue2/SvgIcon'
import FormRadio from '@pixelstein/ps-form/components/PsFormRadio'
import SearchBar from '@/components/SearchBar'
import RouterLinkBack from '@/components/RouterLinkBack'
import MultiLayoutList from '@/components/MultiLayoutList'
import lodashProxy from '@/mixins/lodash-proxy'
import DropDown from '@/components/DropDown'
import _cloneDeep from 'lodash/cloneDeep'
import _isEqual from 'lodash/isEqual'
import viewDataCache from '@/mixins/view-data-cache'
import LoadingScreen from '@/components/LoadingScreen'
import { saveAs } from 'file-saver'
import {
  ACTION_CREATE,
  ACTION_DELETE,
  ACTION_READ,
  ACTION_UPDATE,
  hasPermission,
  PERMISSION_SELF,
} from '@/utils/permissions.js'
import reports from '@/store/Api/Reports.js'

export default {
  name: 'ReportList',
  mixins: [lodashProxy, viewDataCache],
  components: {
    SvgIcon,
    SearchBar,
    RouterLinkBack,
    DropDown,
    MultiLayoutList,
    FormRadio,
    LoadingScreen,
  },
  props: {
    reportConfigId: { type: String, default: null },
  },
  data () {
    return {
      searchQuery: '',
      lastSearchQuery: '',
      loading: 0,
      activeReportState: '',
      page: 1,
      itemsPerPage: 20,
      scrollPos: 0,
      loading_error: true,
      reports: [],
      filter: {},
      lastFilter: {},
      lastFilteredReports: null,
    }
  },
  computed: {
    ...mapState({
      user: state => state.user,
    }),
    ...mapGetters({
      findReportConfig: 'Api/ReportConfigs/find',
    }),
    cacheableKeys () {
      return ['filter', 'searchQuery', 'activeReportState']
    },
    reportConfig () {
      return this.findReportConfig(this.reportConfigId)
    },
    reportStates () {
      if (!this.reportConfig?.states) {
        return []
      }

      return this.reportConfig.states
          .filter(state => !!state.label)
    },
    labeledReportStates () {
      const states = [
        {
          value: '',
          label: this.$t('report_list.unfiltered_label', { count: this.reports.length }),
        },
        {
          value: null,
          label: this.$t('report_list.no_status_label', { count: this.filterReportsByState(null)?.length }),
        },
      ]

      return states.concat(_cloneDeep(this.reportStates || []).map(status => {
        status.label = this.$t('report_list.status_filter_label', {
          status: status.label,
          count: this.filterReportsByState(status.value)?.length,
        })
        return status
      }))
    },
    tagFilteredReports() {
      return this.filteredReports
          .filter(report => this.activeReportState === '' || report.state === this.activeReportState)
    },
    filteredReports () {
      if (this.searchQuery === this.lastSearchQuery && _isEqual(this.filter, this.lastFilter)) {
        return this.lastFilteredReports ?? this.reports
      }

      console.log('loading = true in filteredReports')
      this.loading += 1

      const regex = new RegExp(this.searchQuery, 'i')
      this.lastSearchQuery = this.searchQuery;
      this.lastFilter = _cloneDeep(this.filter);

      this.lastFilteredReports = this.reports
          .filter(report => this.matchesFilter(report))
          .filter(report => {
            return this.searchQuery === '' || this.listColumns
                .some(column => !!this.__get(report, column.key)?.match(regex))
          })

      setTimeout(() => {
        console.log('loading = false in filteredReports')
        this.loading = Math.max(0, this.loading - 1)
      }, 1000)

      return this.lastFilteredReports
    },
    flatFields () {
      return this.reportConfig
          ?.fields
          .flatMap(field => field?.options?.contents || field)
          .filter(field => field.type === 'input' && field.options.type !== 'file') || []
    },
    listColumns () {
      return this.flatFields
          .filter(field => !!(field?.backend_list_column || field?.list_column))
          .map(field => ({
            ...field.list_column,
            key: ['data', field.options.name || field.options.key].join('.'),
            label: field.options.label,
            type: field.options.type,
          }))
          .sort((a, b) => a.sorting - b.sorting) || []
    },
    azSortKey () {
      return this.listColumns.find(col => col.useToSort && !this.isDateColumn(col))?.key
    },
    dateSortKey () {
      return 'created'
    },
    sortingOptions () {
      return this.listColumns.map(col => ({
        key: col.key,
        label: col.label,
        comparator: (a, b) => (_.get(a, col.key) ?? '').localeCompare((_.get(b, col.key) ?? '')),
      }))
    },
    itemOptions () {
      const options = [
        {
          group: 'default',
          groupLabel: 'default',
          value: 'view',
          label: this.$t('report_list.view_report'),
          active: false,
        },
        {
          group: 'default',
          groupLabel: 'default',
          value: 'xlsx',
          label: this.$t('report_list.download_xlsx_report'),
          active: false,
        },
        {
          group: 'default',
          groupLabel: 'default',
          value: 'pdf',
          label: this.$t('report_list.download_pdf_report'),
          active: false,
        },
      ]

      if (hasPermission(this.user, 'Reports', ACTION_DELETE)) {
        options.push({
          group: 'default',
          groupLabel: 'default',
          value: 'delete',
          label: this.$t('report_list.delete_report'),
          active: false,
        })
      }

      return options
    },
    canEditReportConfigs () {
      return !!hasPermission(this.user, 'ReportConfigs', ACTION_UPDATE)
    },
    canCreateReports () {
      return !!hasPermission(this.user, 'Reports', ACTION_CREATE)
    },
  },
  methods: {
    ...mapActions({
      allReports: 'Api/Reports/index',
      getReport: 'Api/Reports/view',
      deleteReport: 'Api/Reports/delete',
    }),
    ...mapMutations({
      addReport: 'Api/Reports/addOrUpdate',
    }),
    updateLocalStorage() {
      if (this.activeReportState === '') {
        window.localStorage.setItem('activeReportState' + this.reportConfigId, 'emptyString')
      } else if (this.activeReportState !== null) {
        window.localStorage.setItem('activeReportState' + this.reportConfigId, this.activeReportState)
      } else {
        window.localStorage.setItem('activeReportState' + this.reportConfigId, 'null')
      }
      console.log(window.localStorage.getItem('activeReportState' + this.reportConfigId))
    },
    matchesFilter (report) {
      return Object.entries(this.filter)
          .every(([key, value]) => this.__get(report, key) === value)
    },
    columnValues (listColumn) {
      const values = {}
      const isDateColumn = this.isDateColumn(listColumn)

      this.reports
          .map(report => this.__get(report, listColumn.key))
          .filter(value => value !== null && value !== '' && value !== undefined)
          .forEach(value => values[isDateColumn ? this.formattedDate(value) : value] = 1)

      return Object.keys(values)
          .filter(a => !!a)
          .sort((a, b) => a.localeCompare(b))
    },
    updateFilter (value, listColumn) {
      if (value) {
        this.$set(this.filter, listColumn.key, value)
        window.localStorage.setItem('filter' + listColumn.key + this.reportConfigId, value)
      } else {
        this.$delete(this.filter, listColumn.key)
        window.localStorage.removeItem('filter' + listColumn.key + this.reportConfigId)
      }
    },
    formatListColumn (report, column) {
      const value = this.__get(report, column.key)

      if (!value) {
        return this.$t('report_list.column.empty')
      }

      if (this.isDateColumn(column)) {
        return this.formattedDate(value)
      } else {
        return value
      }
    },
    filterReportsByState (state) {
      return this.reports.filter(report => report.state === state)
    },
    formattedDate (date) {
      return dayjs(date)
          .locale('de')
          .format('dddd DD.MM.YYYY HH:mm')
    },
    isDateColumn (column) {
      return !!['date', 'datetime', 'datetime-local'].find(el => el === column.type)
    },
    openReport (report) {
      this.$router
          .push(this.getReportRouteParameters(report))
          .catch(() => null)
    },
    getReportRouteParameters (report) {
      return {
        name: 'Edit Report',
        params: {
          reportConfigId: this.reportConfigId,
          reportId: report.id,
          mode: 'view',
        },
      }
    },
    async removeReport (item) {
      if (!confirm(this.$t('report_view.confirm_delete'))) {
        return
      }

      const pos = this.reports.findIndex(r => r.id === item.id)

      await this.deleteReport(item)

      if (pos >= 0) {
        this.reports = this.reports.toSpliced(pos, 1)
      }
    },
    async handleItemOption (action, item) {
      switch (action.value) {
        case 'view':
          this.openReport(item)
          break
        case 'delete':
          await this.removeReport(item)
          break
        case 'pdf':
        case 'xlsx': {
          const file = await this.getReport({ id: item.id, type: action.value })

          saveAs(file, item.id + '.' + action.value)
        }
          break
      }
    },
    async loadData () {
      const parameters = {
        $ignorePagination: true,
        $updateCount: false,
        type: 'json',
        limit: 100,
        filter: {
          report_config_id: this.reportConfigId,
        },
      }

      if (hasPermission(this.user, 'Reports', ACTION_READ) === PERMISSION_SELF) {
        parameters.filter.user_id = this.user.id
      }

      this.reports = await this.allReports(parameters)
    },
    isSelected(listColumn) {
      return window.localStorage.getItem('filter' + listColumn.key + this.reportConfigId)
    }
  },
  async mounted () {
    try {
      const handle = setTimeout(() => {
        console.log('loading = true in mounted')
        this.loading += 1
      }, 250)

      await this.loadData()

      clearTimeout(handle)
    } catch (err) {
      console.error('An error occurred while loading the reports')

      this.apiErrorHandler(err)
    } finally {
      console.log('loading = false in mounted')
      this.loading = Math.max(0, this.loading - 1)
      this.filter = {}
    }

    this.listColumns.forEach(listColumn => {
      let filterValue = window.localStorage.getItem('filter' + listColumn.key + this.reportConfigId)
      if (filterValue) {
        this.updateFilter(filterValue, listColumn)
      }
    })

    let state = window.localStorage.getItem('activeReportState' + this.reportConfigId)

    if (state === "emptyString") {
      this.activeReportState = ''
    } else if (state === "null") {
      this.activeReportState = null
    } else {
      this.activeReportState = state
    }
  },
}
</script>
