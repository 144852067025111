<template>
	<div class="accordion-collapse" :class="{collapsed: !active}">
		<div class="accordion-collapse-header" @click="toggleActive">
			<slot name="header" :active="active"/>
		</div>
		<transition :name="transition">
			<div v-if="active" class="accordion-collapse-content pt-2">
				<slot name="content" :active="active"/>
			</div>
		</transition>
	</div>
</template>

<script>
export default {
  name: 'Collapse',
  emits: ['open', 'close'],
  props: {
    transition: {type: String, default: 'collapse-transition'},
    initialActive: {type: Boolean, default: false},
  },
  data() {
    return {
      active: false,
    };
  },
  created() {
    this.active = this.initialActive;
  },
  methods: {
    toggleActive() {
      this.active = !this.active;

      if (this.active) {
        this.$emit('open')
      } else {
        this.$emit('close');
      }

      if (typeof this.$parent.collapseChangedActive === 'function') {
        this.$parent.collapseChangedActive(this, this.active);
      }
    },
  },
};
</script>

