<template>
  <div class="form-content-composer-options-input">
    <form-select
        :label="$t('form_content_composer.options_input.label.type')"
        :options="inputTypes"
        option-value-key="value"
        :value="value.type"
        required
        @input="onInput('type', $event)"
    />

    <form-input
        :label="$t('form_content_composer.options_input.label.name')"
        :value="value.name || value.key"
        required
        @input="onInput('name', $event)"
    />

    <input hidden :required="keyNotUnique"/>
    <div v-if="keyNotUnique" class="ps-form-partial-input-info mb-3">
      <span class="invalid-note invalid">{{ $t('key_not_unique') }}</span>
    </div>

    <form-input
        :label="$t('form_content_composer.options_input.label.label')"
        :value="value.label"
        :required="!['file'].includes(value.type)"
        @input="onInput('label', $event)"
    />

    <template v-if="['file'].includes(value.type)">
      <form-input
          :label="$t('form_content_composer.options_input.label.file_button_first')"
          :value="value.buttonLabelFirstItem"
          required
          @input="onInput('buttonLabelFirstItem', $event)"
      />
      <form-input
          :label="$t('form_content_composer.options_input.label.file_button')"
          :value="value.buttonLabel"
          required
          @input="onInput('buttonLabel', $event)"
      />
    </template>

    <form-switch
        :label="$t('form_content_composer.options_input.label.required')"
        :value="value.required"
        @input="onInput('required', $event)"
    />

    <form-switch
        :label="$t('form_content_composer.options_input.label.cached')"
        :value="value.cached"
        @input="onInput('cached', $event)"
    />

    <template v-if="['number'].includes(value.type)">
      <form-input
          :label="$t('form_content_composer.options_input.label.step')"
          :value="value.step"
          @input="onInput('step', $event)"
      />
      <form-input
          :label="$t('form_content_composer.options_input.label.min_value')"
          :value="value.minValue"
          @input="onInput('minValue', $event)"
      />
      <form-input
          :label="$t('form_content_composer.options_input.label.max_value')"
          :value="value.maxValue"
          @input="onInput('maxValue', $event)"
      />
    </template>

    <template v-if="['select', 'checkbox', 'radio', 'text'].includes(value.type)">
      <ps-accordion-collapse initial-active class="mb-2 bg-white">
        <template #header="{active}">
          <div class="p-2 d-flex align-items-center justify-content-between">
            {{ $t('form_content_composer.options_input.options.label') }}
            <i class="fas fa-chevron-left" :class="{active}" aria-hidden="true"></i>
          </div>
        </template>
        <template #content>
          <div class="p-2 ">
            <div class="form-row bg-secondary text-white">
              <div class="col-6 text-center">
                <i class="fas fa-chevron-down"
                   :class="{'text-primary':sorting === 'value-asc'}"
                   @click="sortOptions('value', 'asc')"
                   aria-hidden="true"></i>
                &nbsp;
                <i class="fas fa-chevron-up"
                   :class="{'text-primary':sorting === 'value-desc'}"
                   @click="sortOptions('value', 'desc')"
                   aria-hidden="true"></i>
              </div>
              <div class="col-6 text-center">
                <i class="fas fa-chevron-down"
                   :class="{'text-primary':sorting === 'label-asc'}"
                   @click="sortOptions('label', 'asc')"
                   aria-hidden="true"></i>
                &nbsp;
                <i class="fas fa-chevron-up"
                   :class="{'text-primary':sorting === 'label-desc'}"
                   @click="sortOptions('label', 'desc')"
                   aria-hidden="true"></i>
              </div>
            </div>
            <div v-for="(option, idx) in value.values"
                 :key="option.id"
                 class="form-row"
                 :class="{'border-bottom border-light': idx !== value.values.length - 1}"
            >
              <div class="col-12 text-right small pt-1">
                <button class="btn btn-sm btn-primary mb-n3"
                        type="button"
                        @click.prevent="moveDown(idx)"
                        v-if="idx < value.values.length-1"
                >
                  <i class="fas fa-chevron-down"
                     aria-hidden="true"></i>
                </button>
                &nbsp;
                <button class="btn btn-sm btn-primary mb-n3"
                        type="button"
                        @click.prevent="moveUp(idx)"
                        v-if="idx > 0"
                >
                  <i class="fas fa-chevron-up"
                     aria-hidden="true"></i>
                </button>
                &nbsp;
                <button type="button"
                        class="btn btn-sm btn-outline-danger mb-n3"
                        @click.prevent="removeOption(idx)"
                ><i class="fas fa-trash" aria-hidden="true"></i></button>
              </div>
              <div class="col-6">
                <form-input
                    :label="$t('form_content_composer.options_input.options.value_label')"
                    :value="option.value"
                    @input="onInput(['values', idx, 'value'], $event)"
                />
              </div>
              <div class="col-6">
                <form-input
                    :label="$t('form_content_composer.options_input.options.label_label')"
                    :value="option.label"
                    @input="onInput(['values', idx, 'label'], $event)"
                />
              </div>
            </div>

            <button type="button"
                    class="btn btn-primary btn-block"
                    @click="onInput(['values', value?.values?.length || 0], getEmptyOption())"
            >
              {{ $t('form_content_composer.options_input.options.add_button') }}
            </button>
          </div>
        </template>
      </ps-accordion-collapse>
    </template>
  </div>
</template>

<script>
import _set from 'lodash/set'
import _cloneDeep from 'lodash/cloneDeep'

import FormSelect from '@pixelstein/ps-form/components/PsFormSelect'
import FormInput from '@pixelstein/ps-form/components/PsFormInput'
import FormSwitch from '@pixelstein/ps-form/components/PsFormSwitch'
import PsAccordionCollapse from 'pixelstein-vue-app-package/src/vue2/PsAccordion/PsAccordionCollapse'
import { v1 } from 'uuid'

export default {
  name: 'FormContentComposerOptionsInput',
  components: { FormSelect, FormInput, FormSwitch, PsAccordionCollapse },
  props: {
    value: { type: Object, default: () => ({ values: [] }) },
    keyNotUnique: { type: Boolean, default: false },
  },
  data () {
    return {
      sorting: '',
    }
  },
  watch: {
    value: {
      deep: true,
      handler (nv, ov) {
        if (nv.type === 'file' && ov.type !== 'file') {
          this.onInput('cssThumbnails', true)
        }
      },
    },
  },
  computed: {
    inputTypes () {
      return [
        { value: 'text', label: this.$t('form_content_composer.options_input.type.text') },
        { value: 'email', label: this.$t('form_content_composer.options_input.type.email') },
        { value: 'date', label: this.$t('form_content_composer.options_input.type.date') },
        { value: 'datetime-local', label: this.$t('form_content_composer.options_input.type.datetime_local') },
        { value: 'number', label: this.$t('form_content_composer.options_input.type.number') },
        { value: 'url', label: this.$t('form_content_composer.options_input.type.url') },
        { value: 'tel', label: this.$t('form_content_composer.options_input.type.tel') },
        { value: 'switch', label: this.$t('form_content_composer.options_input.type.switch') },
        { value: 'checkbox', label: this.$t('form_content_composer.options_input.type.checkbox') },
        { value: 'radio', label: this.$t('form_content_composer.options_input.type.radio') },
        { value: 'select', label: this.$t('form_content_composer.options_input.type.select') },
        { value: 'html', label: this.$t('form_content_composer.options_input.type.html') },
        { value: 'textarea', label: this.$t('form_content_composer.options_input.type.textarea') },
        { value: 'file', label: this.$t('form_content_composer.options_input.type.file') },
        { value: 'hidden', label: this.$t('form_content_composer.options_input.type.hidden') },
        // { value: 'drawing', label: this.$t('form_content_composer.options_input.type.drawing') },
      ]
    },
  },
  methods: {
    getEmptyOption () {
      return { label: '', value: '', id: v1() }
    },
    sortOptions (prop, dir) {
      let sorter

      if (dir === 'asc') {
        this.sorting = prop + '-asc'
        sorter = (a, b) => a[prop].localeCompare(b[prop])
      } else {
        this.sorting = prop + '-desc'
        sorter = (a, b) => b[prop].localeCompare(a[prop])
      }

      const newValue = this.value.values.sort(sorter)

      this.onInput('values', newValue)
    },
    moveDown (idx) {
      if (idx >= this.value.values.length - 1) {
        return
      }

      const newValue = _cloneDeep(this.value.values)
      const value = newValue[idx]
      newValue.splice(idx, 1)
      newValue.splice(idx + 1, 0, value)

      this.onInput('values', newValue)
    },
    moveUp (idx) {
      if (idx < 1) {
        return
      }

      const newValue = _cloneDeep(this.value.values)
      const value = newValue[idx]
      newValue.splice(idx, 1)
      newValue.splice(idx - 1, 0, value)

      this.onInput('values', newValue)
    },
    removeOption (idx) {
      const newValue = _cloneDeep(this.value.values)
      newValue.splice(idx, 1)
      this.onInput('values', newValue)
    },
    onInput (path, value) {
      console.log('onInput', path, value)
      const valueClone = _cloneDeep(this.value)
      _set(valueClone, path, value)

      this.$emit('input', valueClone)
    },
  },
}
</script>
