<template>
  <div class="form-content-composer-options-text">
    <form-radio
        :label="$t('form_content_composer.options_text.label.font_size')"
        class="mb-3"
        :options="textSizeOptions"
        v-model="textSize"
    />
    <div class="checkbox-wrap mb-3">
      <label class="d-block">{{ $t('form_content_composer.options_text.label.font_style') }}</label>
      <form-checkbox
          v-for="style in textStyles"
          :key="style.value"
          :label="style.label"
          v-model="textStyle[style.value]"
      />
    </div>
    <form-input
        :label="$t('form_content_composer.options_text.label.text')"
        :value="value.text"
        @input="onInput('text', $event)"
    />
  </div>
</template>

<script>
import _cloneDeep from "lodash/cloneDeep";
import _set from "lodash/set";

import FormRadio from "@pixelstein/ps-form/components/PsFormRadio";
import FormCheckbox from "@pixelstein/ps-form/components/PsFormCheckbox";
import FormInput from "@pixelstein/ps-form/components/PsFormInput";

export default {
  name: "FormContentComposerOptionsText",
  components: {
    FormRadio,
    FormCheckbox,
    FormInput,
  },
  props: {
    value: {type: Object, default: () => ({})}
  },
  data() {
    return {
      textSize: 'normal',
      textStyle: {}
    }
  },
  watch: {
    textSize(nv, ov) {
      if (nv !== ov) {
        const textStyles = Object.entries(this.textStyle)
            .filter(entry => entry[1])
            .map(([key]) => key)
            .join(' ');

        this.$emit('set-sub-classes', [nv, textStyles].join(' '))
      }
    },
    textStyle: {
      deep: true,
      handler(nv) {
        const textStyles = Object.entries(nv)
            .filter(entry => entry[1])
            .map(([key]) => key)
            .join(' ');

        this.$emit('set-sub-classes', [this.textSize, textStyles].join(' '))
      }
    }
  },
  computed: {
    textSizeOptions() {
      return [
        {value: 'normal', label: this.$t('form_content_composer.text_size_option.normal')},
        {value: 'h1', label: this.$t('form_content_composer.text_size_option.h1')},
        {value: 'h2', label: this.$t('form_content_composer.text_size_option.h2')},
        {value: 'h3', label: this.$t('form_content_composer.text_size_option.h3')},
        {value: 'h4', label: this.$t('form_content_composer.text_size_option.h4')},
        {value: 'h5', label: this.$t('form_content_composer.text_size_option.h5')},
        {value: 'h6', label: this.$t('form_content_composer.text_size_option.h6')},
      ]
    },
    textStyles() {
      return [
        {value: 'font-italic', label: this.$t('form_content_composer.text_style.italic')},
        {value: 'font-weight-bold', label: this.$t('form_content_composer.text_style.bold')},
        // {value: 'text-muted', label: this.$t('form_content_composer.text_style.muted')},
      ]
    },
  },
  beforeDestroy() {
    this.$emit('set-sub-classes', '')
  },
  methods: {
    onInput(path, value) {
      let valueClone = _cloneDeep(this.value);
      _set(valueClone, path, value);

      this.$emit('input', valueClone)
    }
  }
}
</script>

<style scoped>

</style>
