<template>
  <div class="statistics-settings-dataset-filter form-row">
    <div class="col-12 d-flex align-items-center justify-content-between">
      {{ value.field }}
      <button class="btn btn-sm btn-outline-danger" @click="$emit('remove')">
        <i class="fas fa-trash" aria-hidden="true"></i>
      </button>
    </div>
    <div class="col-12">
      <form-select
          :label="$t('statistics.data_set.data_filter.operator')"
          :options="filterOperators"
          option-value-key="value"
          option-label-key="label"
          :value="value.operator"
          @input="onInput('operator', $event)"
      />
    </div>
    <div class="col">
      <form-input
          :label="$t('statistics.data_set.data_filter.value')"
          :value="value.value"
          disabled="disabled"
          @input="onInput('value', $event)"
      />
    </div>
    <div class="col-2 d-flex justify-content-center align-items-center">
      <button
          type="button"
          v-if="suggestions.values.length > 0"
          class="btn btn-primary btn-sm"
          @click="showSuggestions(true)"
          style="right: 7px"
      >
        <i class="fas fa-clipboard-list" aria-hidden="true"></i>
      </button>
    </div>
    <ps-modal-general-modal v-if="showSuggestionList" dialog-classes="modal-lg" @close="showSuggestions(false)">
      <search-bar class="my-2"
                  :placeholder="$t('files.search_in_files')"
                  :debounceWait="700"
                  v-model="searchQuery"
      />
      <table class="table table-responsive" aria-label="Search Results">
        <tr class="bg-secondary">
          <th class="text-white">{{ suggestions.value }}</th>
        </tr>
        <tr
            v-for="val in filteredSuggestions"
            class="suggestion"
            style="cursor: pointer"
            @click="applySuggestion(val)"
        >
          <td>{{ val.value }}</td>
        </tr>
      </table>
    </ps-modal-general-modal>
  </div>
</template>

<script>
import FormInput from "@pixelstein/ps-form/components/PsFormInput";
import FormSelect from "@pixelstein/ps-form/components/PsFormSelect";

import _cloneDeep from "lodash/cloneDeep";
import _set from "lodash/set";
import SearchBar from '@/components/SearchBar.vue'
import PsModalGeneralModal from 'pixelstein-vue-app-package/src/vue2/PsModal/PsModalGeneralModal.vue'

export default {
  name: "StatisticsSettingsDatasetFilter",
  components: {
    SearchBar,
    FormInput,
    FormSelect,
    PsModalGeneralModal
  },
  data() {
    return {
      showSuggestionList: false,
      searchQuery: ''
    }
  },
  props: {
    value: {
      type: Object, required: true
    },
    reportFields: {type: Array, default: () => []},
  },
  computed: {
    filterOperators() {
      return [
        {
          label: this.$t('statistics.data_set.data_filter.operators.is'),
          value: '==='
        },
        {
          label: this.$t('statistics.data_set.data_filter.operators.is_not'),
          value: '!=='
        }
      ]
    },
    filterFields() {
      return this.reportFields
          .filter(field => !!['radio', 'checkbox', 'select'].find(type => field?.options?.type === type))
          .map(field => ({
            label: field?.options?.label,
            value: field?.options?.name || field?.options?.key,
            values: field?.options?.values,
          }))
    },
    suggestions() {
      return this.filterFields.find(field => field.value === this.value.field)
    },
    filteredSuggestions () {
      const queries = this.searchQuery.split(/\s+/)
          .map(q => q.trim())
          .filter(q => q.length > 0)
          .map(q => new RegExp(q, 'i'))

      return this.suggestions.values
          .filter(sug => {
            const values = Object.values(sug)
                .filter(s => typeof s === 'string')

            return queries.every(q => values.some(v => v.match(q)))
          })
    },
  },
  methods: {
    onInput(path, value) {
      let updatedData = _cloneDeep(this.value);
      updatedData = _set(updatedData, path, value)

      this.$emit('input', updatedData)
    },
    showSuggestions(active) {
      this.showSuggestionList = active
    },
    applySuggestion (suggestion) {
      let updatedValue = { ...this.value }

      const valueKeys = Object.keys(this.value)
      Object.entries(suggestion)
          .filter(([key, value]) => valueKeys.find(k => key === k) && !!value)
          .forEach(([key, value]) => updatedValue[key] = value)

      this.$emit('input', updatedValue)
      this.showSuggestionList = false
    },
  }
}
</script>

<style scoped>

</style>
