<template>
  <div class="loading-screen view h-100">
    <div class="row bg-extra-light h-100 align-items-center overflow-auto">
      <div class="col-12 text-center">
        <lottie :options="options" :height="400" :width="400" v-on:animCreated="handleAnimation"/>
      </div>
    </div>
  </div>
</template>

<script>
import Lottie from 'vue-lottie/src/lottie.vue'
import * as animationData from '@/assets/lottie_loading.json';
export default {
  name: "LoadingScreen",
  components: {Lottie},
  props: {
    animationSpeed: {
      default: 1,
      type: Number
    }
  },
  data() {
    return {
      defaultOptions: {animationData: animationData},
      anim: null
    }
  },
  computed: {
    options() {
      const options = { ...this.defaultOptions }
      const data = { ...animationData }
      data.fr = data.fr * this.animationSpeed
      options.animationData = data

      return options
    }
  },
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
      this.play()
    },
    play: function () {
      this.anim.play();
    },
  }
}
</script>
