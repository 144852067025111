<template>
  <div class="dashboard-module view">
    <scroll-container class="col" offset-to=".dashboard-module">
      <template #toolbar>
        <div class="sticky-top px-3 mx-n3 pb-1 pt-2 d-flex justify-content-between align-items-center ">
          <router-link-back to="/dashboards">
            {{ dashboard?.name }}
          </router-link-back>
        </div>
      </template>

      <form-input
          type="text"
          v-model="dashboard.name"
          :required="true"
          v-if="dashboard"
          @change="setName"
      >
        <template #label>
          {{ $t('dashboards.name') }}
        </template>
      </form-input>
      <div class="row">
        <div class="col-12 col-lg-6 statistics-list">
          <template v-for="chartGroup in [{ charts: orderedCharts, inDashboard: true }, { charts: unassignedCharts, inDashboard: false }]">
            <transition-group name="list">
              <div v-for="(item, itemIndex) in chartGroup.charts" :key="item.id">
                <div class="form-row mb-2">
                  <div class="col-3">
                    <b @click="onSelectChart(item)" class="clickable">
                      {{ item.name }}
                    </b>
                  </div>
                  <div class="col">
                    {{ $t('dashboards.includes_data_from') }}
                    <ul>
                      <li v-for="reportConfig in item.report_configs">{{ reportConfig.name }}</li>
                    </ul>
                  </div>
                  <div class="col">
                    <template v-if="chartGroup.inDashboard">
                      <button class="btn btn-sm btn-danger"
                              @click="onRemoveChart(item)"
                      >{{ $t('dashboards.remove_chart') }}
                      </button>
                      &nbsp;
                      <button class="btn btn-sm btn-primary"
                              type="button"
                              @click.prevent="moveDown(itemIndex)"
                              v-if="itemIndex < dashboard.config.order.length-1"
                      >
                        <i class="fas fa-chevron-down"
                           aria-hidden="true"></i>
                      </button>
                      <template v-if="itemIndex > 0">
                        &nbsp;
                        <button class="btn btn-sm btn-primary"
                                type="button"
                                @click.prevent="moveUp(itemIndex)"

                        >
                          <i class="fas fa-chevron-up"
                             aria-hidden="true"></i>
                        </button>
                      </template>
                    </template>
                    <button class="btn  btn-sm btn-primary" @click="onAddChart(item)" v-else>{{
                        $t('dashboards.add_chart')
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </transition-group>
          </template>
        </div>
        <div class="col-12 col-lg-6" v-if="selectedChart">
          <h3>Preview of "{{ selectedChart.name }}"</h3>
          <StatisticChart
              ref="statistic_chart"
              :data="selectedChart.report_configs.flatMap(rc => rc.reports)"
              :report-fields="reportFields"
              :chart-config="selectedChart"
              :dataset-id-key="labelForField(selectedChart?.config?.chart_value)"
              :label="selectedChart.name"
              :chart-type="selectedChart.type"
          />
        </div>
      </div>
    </scroll-container>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import SearchBar from '@/components/SearchBar.vue'
import DropDown from '@/components/DropDown.vue'
import RouterLinkBack from '@/components/RouterLinkBack.vue'
import GeneralListItem from '@/components/GeneralListItem.vue'
import GeneralCard from '@/components/GeneralCard.vue'
import MultiLayoutList from '@/components/MultiLayoutList.vue'
import { mapActions, mapState } from 'vuex'
import StatisticChart from '@/components/Statistics/StatisticsChart.vue'
import FormInput from '@pixelstein/ps-form/components/PsFormInput.vue'
import TagFilter from '@/components/TagFilter.vue'
import PsSortableList from 'pixelstein-vue-app-package/src/vue2/PsSortableList/PsSortableList.vue'
import _cloneDeep from 'lodash/cloneDeep'
import ScrollContainer from '@/components/ScrollContainer.vue'
import { flattenFields } from '@pixelstein/ps-form/utils/reportConfig.js'

export default defineComponent({
  name: 'DashboardEditor',
  components: {
    ScrollContainer,
    TagFilter,
    StatisticChart,
    MultiLayoutList,
    GeneralCard,
    GeneralListItem,
    RouterLinkBack,
    DropDown,
    SearchBar,
    FormInput,
    PsSortableList,
  },
  data () {
    return {
      dashboard: null,
      selectedChart: null,
    }
  },
  props: {
    dashboardId: String,
  },
  computed: {
    ...mapState({
      allCharts: state => state.Api.Charts.all,
    }),
    reportFields () {
      if (!this.selectedChart) {
        return []
      }

      return this.selectedChart.report_configs
          .flatMap(rc => flattenFields(rc.fields))
          .filter((field, idx, array) => array.findIndex(
              f => (f?.options?.name || f?.options?.key) === (field?.options?.name || f?.options?.key)) === idx)
    },
    chartOrder () {
      return this.dashboard?.config?.order ?? []
    },
    unassignedCharts () {
      return (this.allCharts ?? []).filter(chart => !this.dashboardChartIds.includes(chart.id))
    },
    dashboardCharts () {
      return (this.allCharts ?? []).filter(chart => this.dashboardChartIds.includes(chart.id))
    },
    orderedCharts () {
      return this.dashboardCharts
          .toSorted((a, b) => this.chartOrder.findIndex(i => i === a.id) - this.chartOrder.findIndex(i => i === b.id))
    },
    dashboardChartIds () {
      return this.dashboard?.charts?.map?.(chart => chart.id) ?? []
    },
  },
  methods: {
    ...mapActions({
      loadDashboard: 'Api/ChartCollections/view',
      loadChart: 'Api/Charts/view',
      updateDashboard: 'Api/ChartCollections/edit',
    }),
    labelForField (key) {
      return this.reportFields.find(field => field?.options?.key === key)?.options?.label
    },
    async onSelectChart (chart) {
      this.selectedChart = await this.loadChart({ id: chart.id, contain: ['report_configs', 'report_configs.reports'] })
    },
    async onAddChart (chart) {
      const charts = _cloneDeep(this.dashboard.charts)
      charts.push(chart)

      this.$set(this.dashboard, 'charts', charts)

      const config = _cloneDeep(this.dashboard.config ?? {
        order: [
          ...this.dashboard.charts.map(c => c.id),
        ],
      })
      config.order.push(chart.id)

      config.order = config.order.filter((id, index) => index === config.order.findIndex(i => i === id))

      this.$set(this.dashboard, 'config', config)

      await this.updateDashboard(this.dashboard)
    },
    async onRemoveChart (chart) {
      const charts = _cloneDeep(this.dashboard.charts)
      const index = charts.findIndex(c => chart.id === c.id)

      if (index < 0) {
        return
      }

      charts.splice(index, 1)

      this.$set(this.dashboard, 'charts', charts)

      const config = _cloneDeep(this.dashboard.config ?? {
        order: [
          ...this.dashboard.charts.map(c => c.id),
        ],
      })

      const orderIndex = config.order.findIndex(id => chart.id === id)

      if (orderIndex >= 0) {
        config.order.splice(orderIndex, 1)
            .filter((id, index) => index === config.order.findIndex(i => i === id))

        this.$set(this.dashboard, 'config', config)
      }

      await this.updateDashboard(this.dashboard)
    },
    async moveDown (idx) {
      if (idx >= this.dashboard.config.order.length - 1) {
        return
      }

      const newValue = _cloneDeep(this.dashboard.config.order)
      const value = newValue[idx]
      newValue.splice(idx, 1)
      newValue.splice(idx + 1, 0, value)

      this.$set(this.dashboard.config, 'order', newValue)

      await this.updateDashboard(this.dashboard)
    },
    async moveUp (idx) {
      if (idx < 1) {
        return
      }

      const newValue = _cloneDeep(this.dashboard.config.order)
      const value = newValue[idx]
      newValue.splice(idx, 1)
      newValue.splice(idx - 1, 0, value)

      this.$set(this.dashboard.config, 'order', newValue)

      await this.updateDashboard(this.dashboard)
    },
    async setName () {
      await this.updateDashboard(this.dashboard)
    },
  },
  async mounted () {
    this.dashboard = await this.loadDashboard({
      id: this.dashboardId,
      type: 'json',
      contain: ['charts'],
    })
  },
})
</script>
