export default {
	methods: {
		findTotalOffset(node, stopSelector = 'html') {
      if (!node) {
        return 0
      }
      
			const lastNode = document.querySelector(stopSelector)
			const parent = node.parentElement;
			if (parent
				&& node !== parent
				&& node.clientHeight <= node.scrollHeight
				&& node !== lastNode
			) {
				return this.findTotalOffset(parent, stopSelector) + node.offsetTop
			}
			return node.offsetTop
		},
	}
}
