<template>
  <div>
    <p>
      {{$t('help.dashboards.description')}}
    </p>
    <p>
      {{$t('help.dashboards.xlsx')}}
    </p>
    <div class="mt-4 mb-2 menu" :class="{active: openParagraph === 'create'}" @click="setParagraph('create')">
      <svg-icon name="arrow_right"/><h5 class="d-inline mx-2"><b>{{$t('help.dashboards.create.title')}}</b></h5>
    </div>
    <div class="menu-item" :class="{active: openParagraph === 'create'}">
      <ul>
        <li>{{$t('help.dashboards.create.tab')}}</li>
        <li>{{$t('help.dashboards.create.new')}}</li>
        <li>{{$t('help.dashboards.create.name')}}</li>
        <li>{{$t('help.dashboards.create.add_statistic')}}</li>
        <li>{{$t('help.dashboards.create.sort')}}</li>
        <li>{{$t('help.dashboards.create.remove')}}</li>
        <li>{{$t('help.dashboards.create.preview')}}</li>
        <li>{{$t('help.dashboards.create.save_and_view')}}</li>
        <li>{{$t('help.dashboards.create.edit_or_download')}}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon.vue'
export default {
  name: 'DashboardsHelp',
  components: { SvgIcon },
  data() {
    return {
      openParagraph: "",
    }
  },
  methods: {
    setParagraph(string) {
      this.openSubtypes = false
      this.openParagraph === string ? this.openParagraph = '' : this.openParagraph = string
    }
  }
}
</script>