<template>
  <div class="multi-layout-list">
    <div ref="toolbar" class="toolbar-row"
         :class="{'sticky-top': stickyToolbar}"
         :style="'top: '+stickyToolbar?topOffset:0+'px !important'"
    >
      <div class="col-md-10">
        <slot name="headerToolbar"/>
      </div>
      <div class="col-md-2 list-options text-right">

        <list-style-control
            :options="listStyles"
            v-model="listStyle"
        />

        <drop-down v-if="azSortKey || dateSortKey" class="ml-3 mr-1"
                   :options="sortOptions"
                   @click="updateSortOptions"
        >
          <svg-icon name="list_sorting"/>
        </drop-down>

      </div>
    </div>

    <div :id="listWrapId"
         ref="scroll_wrap"
         class="list-scroll-wrap"
         :style="'height: '+scrollWrapHeight+'px !important'"
         @scroll="onScroll">
      <masonry-collection
          v-if="listStyle === 'cards'"
          ref="masonry"
          :items="slicedItems"
      >
        <template #static_item_before>
          <slot name="static_masonery_item_before"/>
        </template>
        <template #default="{item}">
          <slot :item="item" :list-style="listStyle" name="cardTemplate"/>
        </template>
      </masonry-collection>
      <sortable-list
          v-else
          :items="slicedItems"
          :default-sort-key="null"
          :unique-key="uniqueKey"
      >
        <template #static_item_before>
          <slot name="static_list_item_before"/>
        </template>
        <template #itemTemplate="{item}">
          <slot :item="item" :list-style="listStyle" name="listItemTemplate"/>
        </template>
      </sortable-list>
      <loading-screen v-if="loading"/>
    </div>
  </div>
</template>

<script>
import { v1 } from 'uuid'
import PullToRefresh from 'pulltorefreshjs'

import lodashProxy from '@/mixins/lodash-proxy'
import Offset from '@/mixins/offset'

import SortableList from 'pixelstein-vue-app-package/src/vue2/PsSortableList/PsSortableList'
import SvgIcon from 'pixelstein-vue-app-package/src/vue2/SvgIcon'
import MasonryCollection from '@/components/MasonryCollection'
import ListStyleControl from '@/components/ListStyleControl'
import DropDown from '@/components/DropDown'
import LoadingScreen from '@/components/LoadingScreen.vue'

export default {
  name: 'MultiLayoutList',
  mixins: [lodashProxy, Offset, LoadingScreen],
  components: {
    LoadingScreen,
    SvgIcon,
    MasonryCollection,
    SortableList,
    ListStyleControl,
    DropDown,
  },
  props: {
    items: { type: Array, default: () => [] },
    stickyToolbar: { type: Boolean, default: true },
    uniqueKey: { type: String, default: 'id' },
    listKey: { type: String, default: '' },
    azSortKey: { type: String, default: null },
    dateSortKey: { type: String, default: null },
    offsetTo: { type: String, default: 'html' }, // selector
    scrollThreshold: { type: Number, default: 100 }, // px before end
    initialScrollPos: { type: Number, default: 0 },
    initialPage: { type: Number, default: 1 },
    itemsPerPage: { type: Number, default: 20 },
    loading: { type: Boolean, default: false },
    enablePullRefresh: { type: Boolean, default: false },
    pullRefreshOptions: { type: Object, default: () => ({}) },
    sortingOptions: { type: Array, default: () => ([]) },
  },
  data () {
    return {
      topOffset: 0,
      toolbarHeight: 0,
      parentHeight: 0,
      page: 1,
      listStyles: [
        { type: 'list', icon: 'list_style_list' },
        { type: 'cards', icon: 'list_style_cards' },
      ],
      listStyle: 'list',
      sortOptions: [],
      listWrapId: null,
    }
  },
  computed: {
    scrollWrapHeight () {
      return this.parentHeight - (this.topOffset + this.toolbarHeight)
    },
    activeSortOptions () {
      const activeOptions = {}
      this.sortOptions
          .filter(opt => opt.active)
          .forEach(opt => {
            activeOptions[opt.group] = opt
          })

      return activeOptions
    },
    comparator () {
      const activeOption = this.activeSortOptions.sorting
      const directionFactor = this.activeSortOptions.direction.value === 'asc'? 1: -1

      if (!activeOption) {
        return () => 0
      } else if (activeOption.comparator) {
        return (a, b) => activeOption.comparator(a, b) * directionFactor
      } else if (activeOption.property) {
        return (a, b) => this.__get(a, activeOption.property)?.localeCompare(this.__get(b, activeOption.property)) * directionFactor
      } else {
          switch (this.activeSortOptions.sorting) {
            case 'az':
              return (a, b) => this.__get(a, this.azSortKey)?.localeCompare(this.__get(b, this.azSortKey)) * directionFactor
            case 'date':
              return (a, b) => this.__get(a, this.dateSortKey)?.localeCompare(this.__get(b, this.dateSortKey)) * directionFactor
          }
      }
    },
    sortedItems () {
      return this.items.toSorted((a, b) => {
        return this.comparator(a, b)
      })
    },
    slicedItems () {
      return this.sortedItems.slice(0, this.page * this.itemsPerPage)
    },
  },
  watch: {
    items: {
      deep: true,
      handler () {
        if (this.listStyle === 'cards') {
          this.$refs.masonry.reLayoutMasonry()
        }

        this.page = 1
        this.$emit('scroll', 0)
        this.$refs?.scroll_wrap?.scroll({
          top: 0,
        })
      },
    },
    dateSortKey () {
      this.setSortOptions()
    },
    azSortKey () {
      this.setSortOptions()
    },
    listStyle (nv) {
      if (this.listKey) {
        window.localStorage.setItem('listStyle' + this.listKey, nv)
      }
      this.$emit('list-style-change', nv)
    },
  },
  methods: {
    setSortOptions () {
      const options = this.sortingOptions.map(opt => ({
        group: 'sorting',
        groupLabel: this.$t('sorting.sorting'),
        value: opt.key,
        label: opt.label,
        active: false,
        property: opt.property,
        comparator: opt.comparator,
      }))

      if (this.dateSortKey) {
        options.unshift({
          group: 'sorting',
          groupLabel: this.$t('sorting.sorting'),
          value: 'date',
          label: this.$t('sorting.date'),
          active: false,
          property: this.dateSortKey,
        })
      }

      if (this.azSortKey) {
        options.unshift({
          group: 'sorting',
          groupLabel: this.$t('sorting.sorting'),
          value: 'az',
          label: this.$t('sorting.az'),
          active: false,
          property: this.azSortKey,
        })
      }

      if (options.length) {
        if (this.listKey) {
          for (let i = 0; i < options.length; i++) {
            let value = window.localStorage.getItem('sortOption' + options[i].group + this.listKey)
            if (options[i].value === value) {
              options[i].active = true
            }
          }
        }
        let activeOption = options.find(opt => opt.active === true)
        if (!activeOption) {
          options[0].active = true
        }
      }

      let active = window.localStorage.getItem('sortOption' + 'direction' + this.listKey)

      this.sortOptions = [
        ...options,
        {
          group: 'direction',
          groupLabel: this.$t('sorting.direction'),
          value: 'asc',
          label: this.$t('sorting.asc'),
          active: !(active && active === 'desc'),
        },
        {
          group: 'direction',
          groupLabel: this.$t('sorting.direction'),
          value: 'desc',
          label: this.$t('sorting.desc'),
          active: !!(active && active === 'desc'),
        },
      ]
    },
    setTopOffset () {
      this.$nextTick(() => {
        this.topOffset = this.findTotalOffset(this.$el, this.offsetTo)
        this.toolbarHeight = this.$refs?.toolbar?.clientHeight || 0
        this.parentHeight = this.$el?.parentElement?.clientHeight || 0
      })
    },
    updateSortOptions (option) {
      this.sortOptions = this.sortOptions.map(opt => {
        if (opt.group === option.group && opt.value !== option.value) {
          opt.active = false
        } else if (opt.group === option.group && opt.value === option.value) {
          opt.active = true
          if (this.listKey) {
            window.localStorage.setItem('sortOption' + opt.group + this.listKey, opt.value)
            console.log(window.localStorage.getItem('sortOption' + opt.group + this.listKey))
          }
        }
        return opt
      })

      this.$emit('scroll', 0)
      this.$nextTick(() => this.$emit('sort-option-change', this.sortOptions))
    },
    onScroll ({ target }) {
      this.$emit('scroll', target.scrollTop)

      const scrollTopMax = target.scrollHeight - target.clientHeight
      if (target.scrollTop > scrollTopMax - this.scrollThreshold) {
        this.page++
        this.$emit('scroll-to-end')
      }
    },
  },
  created () {
    this.page = this.initialPage
    this.listWrapId = 'id_' + v1()

    if (this.initialSortOptions) {
      this.sortOptions = this.initialSortOptions
    } else {
      this.setSortOptions()
    }
  },
  mounted () {
    let lastListStyle = window.localStorage.getItem('listStyle' + this.listKey)

    if (lastListStyle) {
      this.listStyle = lastListStyle
    } else
    if (this.initialListStyle) {
      this.listStyle = this.initialListStyle
    }

    this.$nextTick(this.setTopOffset)

    this.resizeObserver = new window.ResizeObserver(this.setTopOffset)
    this.resizeObserver.observe(this.$el.parentElement)

    if (this.enablePullRefresh) {
      this.ptr = PullToRefresh.init({
        mainElement: '#' + this.listWrapId,
        onRefresh: () => {
          this.$emit('pull-refresh')
        },
        ...this.pullRefreshOptions,
        shouldPullToRefresh () {
          return !this.mainElement.scrollTop
        },
      })
    }

    this.$nextTick(() => {
      this.$refs?.scroll_wrap?.scroll({
        top: this.initialScrollPos,
      })
    })

  },
  beforeDestroy () {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
    }

    if (this.ptr) {
      this.ptr.destroy()
    }
  },
}
</script>
