'use strict'

import _cloneDeep from 'lodash/cloneDeep.js'

function extractInputFields (fields) {
  return fields
    .flatMap(field => {
      if (Array.isArray(field.options?.contents)) {
        return extractInputFields(field.options?.contents)
      } else {
        return [field]
      }
    })
    .filter(field => ['input', 'canvas'].includes(field.type))
}

export default {
  computed: {
    reportConfigFields () {
      return this.reportConfig?.fields ?? []
    },
    inputFields () {
      return extractInputFields(this.reportConfigFields)
    },
    inputKeys () {
      return [
        ...new Set(
          this.inputFields
            .map(field => field?.options?.key || field?.options?.name),
        )]
    },
    mandatoryFieldKeys () {
      return [
        ...new Set(
          this.inputFields
            .filter(field => field?.options?.required)
            .map(field => field?.options?.key || field?.options?.name),
        )]
    },
  },
  methods: {
    checkMandatoryFields () {
      return this.mandatoryFieldKeys.every(key => !!this.report.data[key])
    },
    changeFields (fields, option, value) {
      fields = _cloneDeep(fields)
      
      return fields.map(field => {
        field.options[option] = value
        if (Array.isArray(field.options?.contents)) {
          field.options.contents = this.changeFields(field.options.contents, option, value)
        }
        
        return field
      })
    },
  }
}
