<template>
  <div class="form-content-composer-options-canvas">

    <form-input
        :label="$t('form_content_composer.options_canvas.label.name')"
        :value="value.name || value.key"
        required
        @input="onInput('name', $event)"
    />

    <input hidden :required="keyNotUnique"/>
    <div v-if="keyNotUnique"  class="ps-form-partial-input-info mb-3">
      <span class="invalid-note invalid">{{ $t('key_not_unique') }}</span>
    </div>

    <form-input
        :label="$t('form_content_composer.options_canvas.label.label')"
        :value="value.label"
        required
        @input="onInput('label', $event)"
    />

    <form-input
        :label="$t('form_content_composer.options_canvas.label.clear')"
        :value="value.clear"
        required
        @input="onInput('clear', $event)"
    />

    <form-input
        :label="$t('form_content_composer.options_canvas.label.undo')"
        :value="value.undo"
        required
        @input="onInput('undo', $event)"
    />

    <form-input
        :label="$t('form_content_composer.options_canvas.label.unlock')"
        :value="value.unlock"
        required
        @input="onInput('unlock', $event)"
    />

    <form-switch
        :label="$t('form_content_composer.options_input.label.required')"
        :value="value.required"
        @input="onInput('required', $event)"
    />

  </div>
</template>

<script>
import _set from 'lodash/set'
import _cloneDeep from 'lodash/cloneDeep'

import FormInput from "@pixelstein/ps-form/components/PsFormInput";
import FormSwitch from "@pixelstein/ps-form/components/PsFormSwitch";

export default {
  name: "FormContentComposerOptionsCanvas",
  components: {FormInput, FormSwitch},
  props: {
    value: {type: Object, default: () => ({values: {}})},
    keyNotUnique: {type: Boolean, default: false}
  },
  methods: {
    onInput(path, value) {
      console.log('onInput', path, value)

      let valueClone = _cloneDeep(this.value);
      _set(valueClone, path, value);

      console.log('onInput valueClone', valueClone)

      this.$emit('input', valueClone)
    }
  }
}
</script>
