<template>
  <div class="form-content-composer-options-image ps-form-multi-file-upload">
    <div v-if="thumbnail" class="text-center mb-3 w-100">
      <img class="mw-100" :src="thumbnail" :alt="value.filename"/>
    </div>
    <label class="file-upload-label">
      <input type="file"
             @invalid.prevent="$emit('validity-change', false)"
             @valid.prevent="$emit('validity-change', true)"
             @change="addFiles"
             :accept="[...allowedMimeTypes, ...allowedFileExtensions].join()">
      <div>
        {{ $t('report_editor.image') }}
      </div>
    </label>
  </div>
</template>

<script>
import FormInput from '@pixelstein/ps-form/components/PsFormInput'
import prettyBytes from 'pretty-bytes'
import { v1 } from 'uuid'

export default {
  name: 'FormContentComposerOptionsImage',
  components: { FormInput },
  props: {
    value: { type: Object, default: null },
    allowedMimeTypes: { type: Array, default: () => ['image/png', 'image/jpeg'] },
    allowedFileExtensions: { type: Array, default: () => ['jpg', 'jpeg', 'png'] }, // used for the accept attribute
  },
  data() {
    return {
      files: []
    }
  },
  computed: {
    thumbnail() {
      if (this.value.thumbnail && this.value.thumbnail.includes(this.$config.API_BASE_URL)) {
        return this.value.thumbnail
      }

      if (this.value.thumbnail && !this.value.thumbnail.includes(this.$config.API_BASE_URL)) {
        return this.$config.API_BASE_URL + '/' + this.value.thumbnail
      }
    }
  },
  methods: {
    async addFiles ({ target: { files } }) {
      const newFiles = []

      for (const file of files) {
        if (this.files.length === this.maxFileCount) {
          this.$toast.open({
            type: 'error',
            message: this.$t('multi_file_upload.errors.max_files_reached', { count: this.maxFileCount }),
            position: this.$config.TOAST_POSITION,
          })
          break
        }

        const matchedMime = this.allowedMimeTypes.length === 0
            || this.allowedMimeTypes.find(mime => {
              const pattern = mime.replaceAll('*', '.*')
              const regex = new RegExp(pattern, 'i')
              return !!file.type.match(regex)
            })

        const fileExtension = file.name.split('.').pop()
        const matchedExt = this.allowedFileExtensions.length === 0
            || this.allowedFileExtensions.find(ext => ext.split('.').pop() === fileExtension)

        if (!matchedMime && !matchedExt) {
          this.$toast.open({
            type: 'error',
            message: this.$t('multi_file_upload.errors.mime_type_or_ext', {
              filename: file.name,
              type: file.type,
              ext: '.' + fileExtension,
              allowed: [...this.allowedMimeTypes, ...this.allowedFileExtensions].join(', '),
            }),
            position: this.$config?.TOAST_POSITION ?? 'top',
          })

          continue
        }

        const fileSizeSummary = this.files.map(f => f.file.size)
            .reduce((previousValue, currentValue) => currentValue + previousValue, 0)

        if (this.maxFileSize !== 0 && file.size > ((this.maxFileSize * .9) - fileSizeSummary)) {
          this.$toast.open({
            type: 'error',
            message: this.$t('multi_file_upload.errors.file_size', {
              filename: file.name,
              size: prettyBytes(file.size, { locale: this.locale }),
              maxFilesize: prettyBytes(this.maxFileSize * .9, { locale: this.locale }),
              availableSize: prettyBytes(this.maxFileSize * .9 - fileSizeSummary, { locale: this.locale }),
            }),
            position: this.$config?.TOAST_POSITION ?? 'top',
          })

          continue
        }

        const newFile = {
          file: file,
          filesize: prettyBytes(file.size, { locale: this.locale }),
          thumbnail: await this.createImageUrl(file),
          name: file.name,
          price: 1,
          format: '',
          id: v1(),
        }

        this.files.push(newFile)

        newFiles.push(newFile)
      }

      this.inputCount++

      this.emitUpdate([...newFiles])
    },
    removeFile(item) {
      const idx = this.files.findIndex(file => file.id === item.id);
      if (idx > -1) {
        this.files.splice(idx, 1);
      }
    },
    emitUpdate(files) {
      const onProgress = (idx, progress) => {
        if (this.files[idx]) {
          this.$set(this.files[idx], 'progress', progress);
        }
      }
      const onSuccessCallback = (idx) => {
        if (this.files[idx]) {
          this.$set(this.files[idx], 'success', true)
        }
      }
      const onErrorCallback = (idx) => {
        if (this.files[idx]) {
          this.$set(this.files[idx], 'success', false);
          this.$set(this.files[idx], 'progress', 0);
        }
      }
      const onFinallyCallback = (idx) => {
        this.loading = false
        if (this.files.every(file => file.success) && this.files[idx]) {
          this.$nextTick(() => {this.removeFile(this.files[idx])})
          console.log('cleared')
        }

      }

      const progressingFiles = files.filter(file => !file.success)

      this.$emit('input', {
        progressingFiles,
        onProgress,
        onSuccessCallback,
        onErrorCallback,
        onFinallyCallback,
      })
    },
    createImageUrl (file) {
      return new Promise(((resolve, reject) => {

        const reader = new FileReader()
        reader.onload = () => {
          resolve(reader.result)
        }

        reader.onerror = ev => reject(ev)

        reader.readAsDataURL(file)
      }))
    },
  },
}
</script>
