<template>
  <ul class="module-navigation">
    <li class="nav-item" v-for="item in items">
      <div @click="navigateTo(item)" class="nav-link" :class="{active: item.name === $route.name}">
        <svg-icon :name="item.meta.menu.icon"/>
        <span v-html="$t(item.meta.menu.title)"></span>
      </div>
    </li>
    <li class="toggle nav-item">
      <span class="nav-link" @click="toggleMenu">
        <svg-icon class="min-svg" name="arrow_left"/>
        <span v-html="$t('menu.minimize')"></span>
      </span>
    </li>
  </ul>
</template>

<script>
import SvgIcon from '@/components/SvgIcon'

export default {
  name: 'ModuleNavigation',
  emits: ['toggle-menu'],
  components: {
    SvgIcon,
  },
  props: {
    items: Array,
  },
  methods: {
    navigateTo(item) {
      if (item.name !== this.$route.name) {
        window.localStorage.setItem('nav', item.name)
        this.$router.push({name: item.name})
      }
    },
    toggleMenu() {
      this.$emit('toggle-menu')
    },
    async redirect() {
      const save = window.localStorage.getItem('nav')
      if (save && save !== this.$route.name) {
        await this.$router.push({name: save}).catch(() => null)
      }
    }
  },
  mounted () {
    this.redirect()
  }
}
</script>
