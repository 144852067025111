import imageThumbnail from '@/assets/defaultThumbnails/image.svg'
import videoThumbnail from '@/assets/defaultThumbnails/video.svg'
import audioThumbnail from '@/assets/defaultThumbnails/audio.svg'
import wordThumbnail from '@/assets/defaultThumbnails/word.svg'
import excelThumbnail from '@/assets/defaultThumbnails/excel.svg'
import powerpointThumbnail from '@/assets/defaultThumbnails/powerpoint.svg'
import pdfThumbnail from '@/assets/defaultThumbnails/pdf.svg'
import threeDThumbnail from '@/assets/defaultThumbnails/3d.svg'
import htmlThumbnail from '@/assets/defaultThumbnails/html.svg'
import fileThumbnail from '@/assets/defaultThumbnails/file.svg'

export default {
	data() {
		return {
			groupedFileTypes: [
				{
					title: this.$t('files.file_group.image'),
					active: false,
					mimeTypes: this.$config.MIME_TYPES.IMAGE,
					defaultThumbnail: imageThumbnail
				},
				{
					title: this.$t('files.file_group.video'),
					active: false,
					mimeTypes: this.$config.MIME_TYPES.VIDEO,
					defaultThumbnail: videoThumbnail
				},
				{
					title: this.$t('files.file_group.audio'),
					active: false,
					mimeTypes: this.$config.MIME_TYPES.AUDIO,
					defaultThumbnail: audioThumbnail
				},
				{
					title: this.$t('files.file_group.doc'),
					active: false,
					mimeTypes: this.$config.MIME_TYPES.DOC,
					defaultThumbnail: wordThumbnail
				},
				{
					title: this.$t('files.file_group.xls'),
					active: false,
					mimeTypes: this.$config.MIME_TYPES.XLS,
					defaultThumbnail: excelThumbnail
				},
				{
					title: this.$t('files.file_group.ppt'),
					active: false,
					mimeTypes: this.$config.MIME_TYPES.PPT,
					defaultThumbnail: powerpointThumbnail
				},
				{
					title: this.$t('files.file_group.pdf'),
					active: false,
					mimeTypes: this.$config.MIME_TYPES.PDF,
					defaultThumbnail: pdfThumbnail
				},
				{
					title: this.$t('files.file_group.3d'),
					active: false,
					mimeTypes: this.$config.MIME_TYPES["3D"],
					defaultThumbnail: threeDThumbnail
				},
				{
					title: this.$t('files.file_group.html'),
					active: false,
					mimeTypes: this.$config.MIME_TYPES.HTML,
					defaultThumbnail: htmlThumbnail
				},
			],
			noFileType: {
				title: this.$t('files.file_group.file'),
				active: false,
				defaultThumbnail: fileThumbnail
			},
		}
	},
	methods: {
		getDefaultThumbnail(file) {
			return this.groupedFileTypes.find(ft => ft.mimeTypes.find(type => type === file.type))?.defaultThumbnail || this.noFileType.defaultThumbnail;
		},
	}
}
