import Vue from 'vue'
import VueRouter from 'vue-router'

import FileModule from '@/views/FileModule/FileModule'
import StatisticModule from '@/views/StatisticsModule/StatisticModule'
import ReportModule from '@/views/ReportModule/ReportModule'
import ReportEditor from '@/views/ReportModule/ReportEditor'
import MessageCenterModule from '@/views/MessageCenterModule'
import DeviceManagementModule from '@/views/DeviceManagementModule'
import HelpModule from '@/views/HelpModule'
import Login from '@/views/Login'
import Logout from '@/views/Logout'
import FileView from '@/views/FileModule/FileView'
import ReportList from '@/views/ReportModule/ReportList'
import ReportView from '@/views/ReportModule/ReportView'
import StatisticsList from '@/views/StatisticsModule/StatisticsList'
import StatisticsForm from '@/views/StatisticsModule/StatisticsForm'
import SettingsModule from '@/views/SettingsModule'
import DashboardModule from '@/views/DashboardModule/DashboardModule.vue'
import DashboardView from '@/views/DashboardModule/DashboardView.vue'
import DashboardEditor from '@/views/DashboardModule/DashboardEditor.vue'
import DashboardCreate from '@/views/DashboardModule/DashboardCreate.vue'
import ForgotPassword from '@/views/ForgotPassword.vue'
import ResetPassword from '@/views/ResetPassword.vue'
import ConfirmInvitation from '@/views/ConfirmInvitation.vue'
import ReportShared from '@/views/ReportModule/ReportShared.vue'
import SharedThanks from '@/views/SharedThanks.vue'
import NotAvailable from '@/views/NotAvailable.vue'
import ReportShareModal from '@/views/ReportModule/ReportShareModal.vue'

import SettingsHelp from '@/components/helpModule/SettingsHelp.vue'
import FilesHelp from '@/components/helpModule/FilesHelp.vue'
import ReportsHelp from '@/components/helpModule/ReportsHelp.vue'
import StatisticsHelp from '@/components/helpModule/StatisticsHelp.vue'
import DashboardsHelp from '@/components/helpModule/DashboardsHelp.vue'
import MessageCenterHelp from '@/components/helpModule/MessageCenterHelp.vue'
import DeviceManagementHelp from '@/components/helpModule/DeviceManagementHelp.vue'

Vue.use(VueRouter)

const routes = [
  {
  	path: '/',
    redirect: '/help',
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/files',
    name: 'Files',
    component: FileModule,
    meta: {
      title: 'page.title.files',
      requiresAuth: true,
      requiredPermissions: [
        'Files', 'Directories', 'Tags', 'FileReferences'
      ],
      menu: {
        position: 10,
        icon: 'file_management',
        title: 'navigation.modules.files',
        text: 'help.files.card_text',
        component: FilesHelp.name
      },
    },
  },
  {
    path: '/files/:fileId',
    name: 'File View',
    component: FileView,
    props: true,
    meta: {
      title: 'page.title.files',
      requiresAuth: true,
      requiredPermissions: [
        'Files', 'Directories', 'Tags', 'FileReferences'
      ]
    },
  },
  {
    path: '/reports',
    name: 'Report Configs',
    component: ReportModule,
    children: [
      {
        path: 'share/:reportConfigId',
        name: 'ReportShareModal',
        component: ReportShareModal,
        props: true
      }
    ],
    meta: {
      title: 'page.title.reports',
      requiresAuth: true,
      requiredPermissions: [
        'Reports', 'ReportConfigs'
      ],
      menu: {
        position: 20,
        icon: 'reports',
        title: 'navigation.modules.reports',
        text: 'help.reports.card_text',
        component: ReportsHelp.name
      },
    },
  },
  {
    path: '/reports/config/new',
    name: 'New Report Config',
    component: ReportEditor,
    meta: {
      title: 'page.title.reports',
      requiresAuth: true,
      requiredPermissions: [
        'Files', 'ReportConfigs'
      ]
    },
  },
  {
    path: '/reports/config/:reportConfigId',
    name: 'Edit Report Config',
    component: ReportEditor,
    props: true,
    meta: {
      title: 'page.title.reports',
      requiresAuth: true,
      requiredPermissions: [
        'Files', 'ReportConfigs'
      ]
    },
  },
  {
    path: '/reports/:reportConfigId',
    name: 'Reports',
    component: ReportList,
    props: true,
    meta: {
      title: 'page.title.reports',
      requiresAuth: true,
      requiredPermissions: [
        'ReportConfigs'
      ]
    },
  },
  {
    path: '/reports/:reportConfigId/:reportId/:mode',
    name: 'Edit Report',
    component: ReportView,
    props: true,
    meta: {
      title: 'page.title.reports',
      requiresAuth: true,
      requiredPermissions: [
        'Files', 'ReportConfigs', 'Reports'
      ]
    },
  },
  {
    path: '/reports/:reportConfigId/:mode',
    name: 'New Report',
    component: ReportView,
    props: true,
    meta: {
      title: 'page.title.reports',
      requiresAuth: true,
      requiredPermissions: [
        'Files', 'ReportConfigs', 'Reports'
      ]
    },
  },
  {
    path: '/shared_reports/:reportConfigId/:token',
    name: 'SharedReport',
    component: ReportShared,
    props: true,
    meta: {
      title: 'page.title.shared_reports',
      requiresAuth: false
    },
  },
  {
    path: '/shared_reports/thanks',
    name: 'SharedThanks',
    component: SharedThanks,
    props: true,
    meta: {
      title: 'page.title.shared_thanks',
      requiresAuth: false
    },
  },
  {
    path: '/statistics',
    name: 'Statistics',
    component: StatisticModule,
    meta: {
      title: 'page.title.statistics',
      requiresAuth: true,
      requiredPermissions: [
        'ReportConfigs', 'Charts'
      ],
      menu: {
        position: 30,
        icon: 'statistics',
        title: 'navigation.modules.statistics',
        text: 'help.statistics.card_text',
        component: StatisticsHelp.name
      },
    },
  },
  {
    path: '/statistics/:reportConfigId',
    name: 'Statistics List',
    component: StatisticsList,
    props: true,
    meta: {
      title: 'page.title.statistics',
      requiresAuth: true,
      requiredPermissions: [
        'ReportConfigs', 'Charts'
      ]
    },
  },
  {
    path: '/statistics/:reportConfigId/new',
    name: 'Statistics New',
    component: StatisticsForm,
    props: true,
    meta: {
      title: 'page.title.statistics',
      requiresAuth: true,
      requiredPermissions: [
        'ReportConfigs', 'Charts'
      ]
    },
  },
  {
    path: '/statistics/:reportConfigId/:action/:chartId',
    name: 'Statistics Edit',
    component: StatisticsForm,
    props: true,
    meta: {
      title: 'page.title.statistics',
      requiresAuth: true,
      requiredPermissions: [
        'ReportConfigs', 'Charts'
      ]
    },
  },
  {
    path: '/dashboards',
    name: 'Dashboards',
    component: DashboardModule,
    meta: {
      title: 'page.title.dashboards',
      requiresAuth: true,
      requiredPermissions: [
        'ChartCollections'
      ],
      menu: {
        position: 40,
        icon: 'dashboards',
        title: 'navigation.modules.dashboards',
        text: 'help.dashboards.card_text',
        component: DashboardsHelp.name
      },
    },
  },
  {
    path: '/dashboards/new',
    name: 'Dashboard Create',
    component: DashboardCreate,
    props: true,
    meta: {
      title: 'page.title.dashboards',
      requiresAuth: true,
      requiredPermissions: [
        'ChartCollections', 'Charts'
      ]
    },
  },
  {
    path: '/dashboards/:dashboardId',
    name: 'Dashboard View',
    component: DashboardView,
    props: true,
    meta: {
      title: 'page.title.dashboards',
      requiresAuth: true,
      requiredPermissions: [
        'ChartCollections'
      ]
    },
  },
  {
    path: '/dashboards/:dashboardId/edit',
    name: 'Dashboard Edit',
    component: DashboardEditor,
    props: true,
    meta: {
      title: 'page.title.dashboards',
      requiresAuth: true,
      requiredPermissions: [
        'ChartCollections', 'Charts'
      ]
    },
  },
  {
    path: '/message-center',
    name: 'Message Center',
    component: MessageCenterModule,
    meta: {
      title: 'page.title.message_center',
      requiresAuth: true,
      requiredPermissions: [
        'Messages'
      ],
      menu: {
        position: 50,
        icon: 'message_center',
        title: 'navigation.modules.message_center',
        text: 'help.message_center.card_text',
        component: MessageCenterHelp.name
      },
    },
  },
  {
    path: '/device-management',
    name: 'Device Management',
    component: DeviceManagementModule,
    meta: {
      title: 'page.title.device_management',
      requiresAuth: true,
      requiredPermissions: [
        'Devices'
      ],
      menu: {
        position: 60,
        icon: 'device_management',
        title: 'navigation.modules.device_management',
        text: 'help.device_management.card_text',
        component: DeviceManagementHelp.name
      },
    },
  },
  {
    path: '/settings',
    name: 'Settings',
    component: SettingsModule,
    meta: {
      title: 'page.title.settings',
      requiresAuth: true,
      roles: 'admin',
      requiredPermissions: [
        'Users'
      ],
      menu: {
        position: 70,
        icon: 'settings',
        title: 'navigation.modules.settings',
        text: 'help.settings.card_text',
        component: SettingsHelp.name
      },
    },
  },
  {
    path: '/help',
    name: 'Help',
    component: HelpModule,
    props: true,
    meta: {
      title: 'page.title.help',
      requiresAuth: true,
      menu: {
        position: 80,
        icon: 'help',
        title: 'navigation.modules.help',
      },
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    props: true,
    meta: {
      title: 'page.title.login',
      requiresAuth: false
    },
  },
  {
    path: '/login/:successRedirect',
    name: 'Login Redirect',
    component: Login,
    props: true,
    meta: {
      title: 'page.title.login',
      requiresAuth: false
    },
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
    meta: {
      title: 'page.title.logout',
      requiresAuth: true,
      requiredPermissions: [
        'Users'
      ]
    },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    props: true,
    meta: {
      title: 'page.title.forgot_password',
      requiresAuth: false
    },
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: ResetPassword,
    props: true,
    meta: {
      title: 'page.title.reset_password',
      requiresAuth: false
    },
  },
  {
    path: '/confirm-invitation/:token',
    name: 'ConfirmInvitation',
    component: ConfirmInvitation,
    props: true,
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: '/404',
    name: 'NotAvailable',
    component: NotAvailable,
    props: true,
    meta: {
      title: 'page.title.not_available',
      requiresAuth: false
    },
  },
  {
    path: '*',
    redirect: {
      name: 'NotAvailable',
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
