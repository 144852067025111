<template>
  <div class="report-view view scroll border border-white">
    <div ref="naviBack" class="sticky-top bg-extra-light px-3 mx-n3 pb-2 pt-2 d-flex justify-content-between align-items-center">
      <router-link-back :to="'/reports/'+reportConfigId">
        {{ reportConfig?.name }}
      </router-link-back>
      <template v-if="mode === 'view'">
        <div>
          {{ $t('report_list.state') }}&nbsp;{{ report.state || '-' }}
        </div>
        <div class="d-flex justify-content-end" style="gap: 1rem">
          <a
              class="btn btn-danger btn-sm"
              @click.prevent="removeReport"
              v-if="canDeleteReport"
          >
            {{ $t('report_view.delete') }}
          </a>

          <router-link
              class="btn btn-primary btn-sm"
              :to="['/reports', reportConfigId, reportId, 'edit'].join('/')"
              v-if="canEditReport"
          >
            {{ $t('report_view.edit') }}
          </router-link>
        </div>
      </template>
      <template v-else>
        <div class="d-flex align-items-center" v-if="mode === 'edit'">
          <div class="mr-2">{{ $t('report_list.state') }}</div>
          <form-select
              v-if="reportConfig?.states"
              :options="reportConfig.states"
              class="d-inline-block"
              style="margin-bottom: 0 !important;"
              option-label-key="label"
              option-value-key="value"
              v-model="report.state"
          />
        </div>

        <div class="d-flex justify-content-end" style="gap: 1rem">
          <button
              type="button"
              class="btn btn-danger btn-sm"
              @click.prevent="removeReport"
              v-if="canDeleteReport"
          >
            {{ $t('report_view.delete') }}
          </button>

          <button
              type="button"
              class="btn btn-primary btn-sm"
              @click.prevent="saveReport"
              v-if="canEditReport"
          >
            {{ $t('report_view.save') }}
          </button>
        </div>
      </template>
    </div>
    <form-configured-content
        v-if="report"
        :contents="reportConfigFields"
        :suggestions="filteredSuggestions"
        :value="report.data"
        primary-file-preview-key="file"
        @input="onReportInput"
        @focus-content="onFocusContent"
        @unlock-fields="unlockContent"
        @lock-fields="lockContent"
        @cancel-fields="cancelContent"
        @copy-values="copyValuesToUpdated"
        @show-file="onShowFile"
    >
      <template #suggestionPicker="{suggestions, active, setActive, applySuggestion}">

        <ps-modal-general-modal v-if="active" dialog-classes="modal-lg" @close="setActive(false)">
          <search-bar class="my-2"
                      :placeholder="$t('files.search_in_files')"
                      :debounceWait="700"
                      v-model="searchQuery"
          />
          <table class="table table-responsive" aria-label="Search Results">
            <tr class="bg-secondary">
              <th v-for="(_col, key) in suggestions[0]" class="text-white">{{ key }}</th>
            </tr>
            <tr
                v-for="row in suggestions"
                class="suggestion"
                style="cursor: pointer"
                @click="applySuggestion(row)"
            >
              <td v-for="col in row">{{ col }}</td>
            </tr>
          </table>
        </ps-modal-general-modal>

      </template>
    </form-configured-content>

    <general-modal
        v-if="previewFile"
        dialog-classes="modal-xl"
        @close="previewFile=null"
    >
      <div class="d-flex justify-content-center">
        <img class="img-fluid" :src="previewFileUrl" alt=""/>
      </div>
    </general-modal>

    <div class="loading-modal" v-if="loading">
      <loading-screen/>
    </div>
  </div>
</template>

<script>

import dayjs from 'dayjs'
import _cloneDeep from 'lodash/cloneDeep'

import { findKeysForSubtype } from '@/helpers/report-config-helper'

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

// import UseSuggestionsDatabase from '@/mixins/use-suggestions-database'

import HorizontalScrollWrap from '@/components/HorizontalScrollWrap.vue'
import SearchBar from '@/components/SearchBar.vue'

import PsModalGeneralModal from 'pixelstein-vue-app-package/src/vue2/PsModal/PsModalGeneralModal'
import FormConfiguredContent from '@pixelstein/ps-form/components/PsFormConfiguredContent'
import FormSelect from '@pixelstein/ps-form/components/PsFormSelect'
import GeneralModal from 'pixelstein-vue-app-package/src/vue2/PsModal/PsModalGeneralModal'
import RouterLinkBack from '@/components/RouterLinkBack'
import LoadingScreen from '@/components/LoadingScreen'
import { ACTION_CREATE, ACTION_DELETE, ACTION_UPDATE, hasPermission } from '@/utils/permissions.js'
import { v1 } from 'uuid'
import formConfig from '@/mixins/form-config.js'
import { collectDateKeys } from '@/utils/reports.js'
import { flattenFields } from '@pixelstein/ps-form/utils/reportConfig.js'

export default {
  name: 'ReportView',
  components: {
    SearchBar,
    HorizontalScrollWrap,
    LoadingScreen,
    RouterLinkBack,
    FormConfiguredContent,
    FormSelect,
    GeneralModal,
    PsModalGeneralModal
  },
  mixins: [formConfig], //, UseSuggestionsDatabase],
  data () {
    return {
      loading: false,
      report: {},
      suggestions: [],
      focusKey: null,
      lockedContainers: [],
      previewFile: null,
      saving: false,
      searchQuery: '',
    }
  },
  props: {
    reportConfigId: { type: String, default: null },
    reportId: { type: String, default: null },
    mode: { type: String, default: 'view' },
  },
  computed: {
    ...mapState({
      user: state => state.user,
    }),
    ...mapGetters({
      findReportConfig: 'Api/ReportConfigs/find',
      resolveApiUrl: 'Api/resolveApiUrl',
    }),
    reportConfig () {
      return this.findReportConfig(this.reportConfigId)
    },
    reportConfigFields () {
      if (!this.report) {
        return []
      }

      let fields = _cloneDeep(this.reportConfig.fields)

      if (this.mode === 'view') {
        return this.changeFields(fields ?? [], 'disabled', true)
      } else {
        return fields ?? []
      }
    },
    reportConfigFileFieldKeys () {
      return this.reportConfigFields
          .flatMap(field => field?.options?.contents || field)
          .filter(field => field?.options?.type === 'file')
          .map(field => field.options.key || field.options.name)
    },
    cachedFields () {
      return this.reportConfigFields
          .filter(field => field?.options?.cached)
    },
    canEditReport () {
      if (this.reportId) {
        return !!hasPermission(this.user, 'Reports', ACTION_UPDATE)
      } else {
        return !!hasPermission(this.user, 'Reports', ACTION_CREATE)
      }
    },
    canDeleteReport () {
      if (this.reportId) {
        return !!hasPermission(this.user, 'Reports', ACTION_DELETE)
      } else {
        return true
      }
    },
    filteredSuggestions () {
      if (this.searchQuery.trim() === '') {
        return this.suggestions
      }

      const queries = this.searchQuery.split(/\s+/)
          .map(q => q.trim())
          .filter(q => q.length > 0)
          .map(q => new RegExp(q, 'i'))

      return Object.values(this.suggestions)
          .filter(sug => {
            const values = Object.values(sug)
                .filter(s => typeof s === 'string')

            return queries.every(q => values.some(v => v.match(q)))
          })
    },
    previewFileUrl () {
      if (!this.previewFile) {
        return null
      }

      return this.previewFile.file
    },
  },
  methods: {
    ...mapMutations({
      setCurrentReport: 'Api/Reports/setCurrent',
      updateReport: 'Api/Reports/addOrUpdate',
      removeReport: 'Api/Reports/remove',
    }),
    ...mapActions({
      getReport: 'Api/Reports/view',
      editReport: 'Api/Reports/edit',
      addReport: 'Api/Reports/add',
      deleteReport: 'Api/Reports/delete',
      addFile: 'Api/Files/add',
      getSuggestion: 'Api/ReportConfigs/suggestions'
    }),
    onShowFile (file) {
      this.previewFile = _cloneDeep(file)
    },
    onReportInput (data) {
      this.$set(this.report, 'data', data)
    },
    async removeReport () {
      if (!confirm(this.$t('report_view.confirm_delete'))) {
        return
      }

      if (this.report.id) {
        await this.deleteReport(this.report)
      }

      this.$toast.open({
        message: this.$t('report_form.delete_success'),
        type: 'success',
        position: this.$config?.TOAST_POSITION ?? 'top',
      })

      this.$router.back()
    },
    async saveReport () {
      if (!this.checkMandatoryFields()) {
        this.$toast.open({
          message: this.$t('report_form.check_mandatory_fields'),
          type: 'error',
          position: this.$config?.TOAST_POSITION ?? 'top',
        })

        return
      }

      const report = _cloneDeep(this.report)
      const dateKeys = collectDateKeys(this.reportConfig.fields)
      dateKeys.forEach(key => report.data[key] = dayjs(report.data[key]).format(this.$config.API_DATE_TIME_FORMAT))

      try {
        this.loading = true

        if (report.id) {
          await this.editReport(report)
        } else {
          this.report = await this.saveNewReport(report)
        }

        this.$toast.open({
          message: this.$t('report_form.save_success'),
          type: 'success',
          position: this.$config?.TOAST_POSITION ?? 'top',
        })

        this.updateFieldCache()

        this.$router.back()
      } catch (err) {
        this.apiErrorHandler(err)
      } finally {
        this.loading = false
        this.saving = false
      }
    },
    async saveNewReport (report) {
      report = _cloneDeep(report)
      report = await this.storeReportFiles(report)

      return this.addReport(report)
    },
    async storeReportFiles (report) {
      if (this.saving) {
        throw new Error('already saving')
      }

      this.saving = true

      if (!report.data._images) {
        report.data._images = {}
      }

      const fileFields = Object.entries(report.data)
          .filter(([k]) => this.reportConfigFileFieldKeys.find(key => k === key))

      for (const [key, files] of fileFields) {
        if (!files) {
          continue
        }

        const updatedFiles = []

        for (const { file } of files) {
          if (!(file instanceof File)) {
            // can only store File objects, skipping this file
            continue
          }

          const response = await this.addFile({ file })

          updatedFiles.push({ id: response.id })
          report.files.push({ id: response.id })
        }

        if (updatedFiles.length === 0) {
          continue
        }

        report.data[key] = updatedFiles

        report.data._images[key] = updatedFiles.map(file => file.id)
      }

      this.saving = false

      return report
    },
    updateFieldCache () {
      this.cachedFields.forEach(field => {
        const key = field?.options?.name || field?.options?.key

        sessionStorage.setItem([
          this.reportConfigId,
          key,
        ].join('-'), this.report.data[key])
      })
    },
    updateDateFields (report) {
      const dateKeys = collectDateKeys(this.reportConfig?.fields ?? [], [])

      dateKeys.forEach(key => {
        if (report.data[key]) {
          report.data[key] = dayjs(report.data[key]).format('YYYY-MM-DDThh:mm')
        }
      })

      return report
    },
    blurAllInputs ({ target }) {
      if (!target.matches('input, label, textarea')) {
        this.$el.querySelectorAll('input').forEach(node => node.blur())
      }
    },
    async onFocusContent ({ key, value, locked }) {
      if (value && !locked) {
        if (Array.isArray(key)) {
          key = key.join('.')
        }

        this.suggestions = await this.getSuggestion({
          id: this.reportConfigId,
          key: key,
          value: value
        })
      } else {
        this.suggestions = []
      }

      this.focusKey = key
    },
    cancelContent(containerId) {
      if (this.report.data) {
        let configFields = {};

        if (this.reportConfigFields.find(field => field.id === containerId)) {
          configFields = this.reportConfigFields.find(field => field.id === containerId);
        } else {
          const containers = this.reportConfigFields.filter(field => ['layout_row', 'collapse_container', 'condition_container'].includes(field.type));
          for (let container of containers) {
            configFields = flattenFields(container.options.contents).flatMap(container => container).find(field => field.id === containerId)
            if (configFields) {
              break;
            }
          }
        }

        let fields = flattenFields(configFields.options.contents).flatMap(container => container);
        fields.forEach(field => {
          if (this.report.data['_updated_' + field.options.name]) {
            this.report.data['_updated_' + field.options.name] = ''
          }
        })
      }
    },
    unlockContent(containerId) {
      if (this.report.data) {
        const locked = _cloneDeep(this.lockedContainers.filter(item => item !== containerId))
        this.lockedContainers = locked
        this.$set(this.report.data, '_$locked', locked)

        let configFields = {};

        if (this.reportConfigFields.find(field => field.id === containerId)) {
          configFields = this.reportConfigFields.find(field => field.id === containerId);
        } else {
          const containers = this.reportConfigFields.filter(field => ['layout_row', 'collapse_container', 'condition_container'].includes(field.type));
          for (let container of containers) {
            configFields = flattenFields(container.options.contents).flatMap(container => container).find(field => field.id === containerId)
            if (configFields) {
              break;
            }
          }
        }

        let fields = flattenFields(configFields.options.contents).flatMap(container => container);
        fields.forEach(field => {
          if (this.report.data[field.options.name]) {
            this.report.data[field.options.name] = ''
          }
          if (this.report.data['_updated_' + field.options.name]) {
            this.report.data['_updated_' + field.options.name] = ''
          }
        })
      }
    },
    lockContent(containerId) {
      if (!this.lockedContainers.includes(containerId)) {
        this.lockedContainers.push(containerId)
        this.$set(this.report.data, '_$locked', _cloneDeep(this.lockedContainers))
      }
    },
    copyValuesToUpdated(event) {
      if (this.report.data) {
        let configFields = {};

        if (this.reportConfigFields.find(field => field.id === event.containerId)) {
          configFields = this.reportConfigFields.find(field => field.id === event.containerId);
        } else {
          const containers = this.reportConfigFields.filter(field => ['layout_row', 'collapse_container', 'condition_container'].includes(field.type));
          for (let container of containers) {
            configFields = flattenFields(container.options.contents).flatMap(container => container).find(field => field.id === event.containerId)
            if (configFields) {
              break;
            }
          }
        }

        let fieldNames = this.getFieldNames(configFields.options.contents);
        fieldNames.forEach(name => {
          if (!this.report.data['_updated_' + name]) {
            this.report.data['_updated_' + name] = this.report.data[name]
          }
        })

        setTimeout(() => this.$el.scrollTo({top: this.$el.scrollTop + event.position - this.$el.getBoundingClientRect().top - this.$refs.naviBack.getBoundingClientRect().height, behavior: 'smooth'}), 200);
      }
    },
    getFieldNames(fields) {
      let children = [];
      let fieldNames = [];
      flattenFields(fields).map(field => {
        if (['layout_row', 'collapse_container'].includes(field.type)) {
          for (const name of this.getFieldNames(field.options.contents)) {
            children.push(name);
          }
        } else {
          if (field.type === "input") {
            fieldNames.push(field.options.name);
          }
        }
      })
      return [...fieldNames, ...children]
    },
    async setAutoCompleteLock(report) {
      const blockContainer = flattenFields(report.report_config.fields).flatMap(container => container).filter(field => ['auto_complete_container'].includes(field.type))

      if (this.report.data['_$locked']) {
        this.lockedContainers = _cloneDeep(this.report.data['_$locked'])
      }

      for (const container of blockContainer) {
        if (this.report.data['_$locked']?.find(item => item === container.id)) {
          this.lockContent(container.id)
        }
      }
    },
  },
  async mounted () {
    try {
      if (this.mode === 'new') {
        const report = {
          data: {},
          files: [],
          user: this.user,
          user_id: this.user?.id,
          state: null,
          report_config_id: this.reportConfigId,
          report_config: this.reportConfig,
          frozen_report_config: this.reportConfig,
          local_id: v1(),
        }

        this.cachedFields
            .forEach(field => {
              const key = field?.options?.name || field?.options?.key

              if (!report.data[key]) {
                report.data[key] = sessionStorage.getItem([
                  this.reportConfigId,
                  key,
                ].join('-')) || ''
              }
            })

        this.report = report

        await this.setAutoCompleteLock(report)

        return
      }

      let report = await this.getReport({
        id: this.reportId,
        type: 'json',
        contain: ['report_configs', 'files', 'users'],
      })

      report = this.updateDateFields(_cloneDeep(report))

      report.files = report.files.map(file => {
        file.thumbnail = this.$config.API_BASE_URL + file.thumbnail
        file.file = this.$config.API_BASE_URL + file.file

        return file
      })

      const uploadFieldKeys = findKeysForSubtype(this.reportConfig?.fields ?? [], 'file')

      Object
          .entries(report.data._images || {})
          .forEach(([key, imageIds]) => {

            if (!uploadFieldKeys.find(uploadKey => key === uploadKey)) {
              return
            }

            report.data[key] = report.files.filter(file => imageIds.find(fileId => fileId === file.id))
          })

      this.report = report

      await this.setAutoCompleteLock(report)

    } catch (err) {
      this.apiErrorHandler(err)
    }
    document.addEventListener('click', this.blurAllInputs)
  },
  beforeDestroy () {
    document.removeEventListener('click', this.blurAllInputs)
  },
}
</script>
