<template>
  <div class="files-module view">

    <search-bar
        class="my-3"
        :placeholder="$t('files.search_in_files')"
        :debounceWait="700"
        v-model="searchQuery"
    />

    <file-directory-menu
        class="my-3"
        :directories="directories"
        @update-directories="updateDirectories"
        v-model="selectedDirectoryId"
        :readonly="!canEditDirectories"
    />

    <multi-layout-list v-if="tagFilteredFileReferences"
                       ref="filelist"
                       :items="mappedFileReferences"
                       :list-key="'file-module'"
                       az-sort-key="title"
                       date-sort-key="modified"
                       offset-to=".view"
                       :initial-page="page"
                       :initial-scroll-pos="scrollPos"
                       @scroll-to-end="page++"
    >
      <template #headerToolbar>

        <div class="my-2">
          <form-checkbox v-for="group in sortedFilteredGroupedFileTypes"
                         :key="group.title"
                         :label="group.title"
                         class="text"
                         :value="group.active"
                         @input="setMimeGroupActive(group, $event)"
          />
        </div>

        <tag-filter
            :tags="filteredTagsOfSelectedDirectory"
            :value="filteredTagsOfSelectedDirectory.filter(({id}) => activeTags[id])"
            @input="updateActiveTags"
        />

      </template>
      <template #static_masonery_item_before v-if="canUploadFiles">
        <multi-file-upload
            v-if="canUploadFiles"
            class="w-100"
            :tags="tags"
            :selected-directory="selectedDirectory"
            :directories="directories"
            :max-file-size="maxUploadFileSize"
            :max-uploads-per-request="maxUploadsPerRequest"
            :allowed-mime-types="$config.UPLOAD_ALLOWED_MIME_TYPES"
            :allowed-type-endings="$config.UPLOAD_ALLOWED_TYPE_ENDINGS"
            @create-tag="createTag"
            @create-directory="createDirectory"
            @upload-file="uploadFile"
        >
          <template #button>
            <div class="file-card">
              <img src="@/assets/defaultThumbnails/add_file.svg" class="card-img-top" alt="add new file"/>
              <div class="card-body">
                <h5 class="card-title">{{ $t('multi_file_upload.add_new_files') }}</h5>
                <p class="card-text">
                </p>
              </div>
            </div>
          </template>
        </multi-file-upload>
      </template>

      <template #cardTemplate="{item}">
        <div @click="handleItemOption({value:'open'}, item)">
          <general-card v-if="!$slots.cardTemplate"
                        :image="item?.file?.thumbnail?$config.API_BASE_URL+item?.file?.thumbnail: null"
                        :default-image="item.default_thumbnail"
                        :title="item?.file?.title"
                        :subtitle="item?.file?.filename"
                        :tags="item.tags"
                        class="mb-3"
                        @click="$emit('open', item?.file)"
          >
            <div @click.stop>
              <drop-down class="ml-3"
                         :options="itemOptions"
                         :drop-up="true"
                         drop-left
                         close-on-choose
                         @click="handleItemOption($event, item)"
              >
                <svg-icon name="ellipsis"/>
              </drop-down>
            </div>
          </general-card>
        </div>
      </template>
      <template #static_list_item_before v-if="canUploadFiles">
        <multi-file-upload
            class="w-100 mb-2"
            :tags="tags"
            :selected-directory="selectedDirectory"
            :directories="directories"
            :max-file-size="maxUploadFileSize"
            :max-uploads-per-request="maxUploadsPerRequest"
            :allowed-mime-types="$config.UPLOAD_ALLOWED_MIME_TYPES"
            :allowed-type-endings="$config.UPLOAD_ALLOWED_TYPE_ENDINGS"
            @create-tag="createTag"
            @create-directory="createDirectory"
            @upload-file="uploadFile"
        >
          <template #button>
            <general-list-item
                :image="addFileThumbnail"
                :title="$t('multi_file_upload.add_new_files')">
            </general-list-item>
          </template>
        </multi-file-upload>

      </template>
      <template #listItemTemplate="{item}">
        <div @click="handleItemOption({value:'open'}, item)">
          <general-list-item
              :image="item?.file?.thumbnail?$config.API_BASE_URL+item?.file?.thumbnail: null"
              :default-image="item.default_thumbnail"
              :title="item?.file?.title"
              :subtitle="item?.file?.filename"
              :tags="item.tags"
              @click="$emit('open', item?.file)"
          >
            <div @click.stop>
              <drop-down class="ml-3"
                         :options="itemOptions"
                         :drop-up="false"
                         drop-left
                         close-on-choose
                         @click="handleItemOption($event, item)"
              >
                <svg-icon name="ellipsis"/>
              </drop-down>
            </div>
          </general-list-item>
        </div>
      </template>
    </multi-layout-list>

    <general-modal v-if="fileReferenceToEdit"
                   dialog-classes="modal-xl"
                   :close-on-backdrop="false"
                   :title="$t('files.edit.header', {filename: fileReferenceToEdit.original_name})"
                   @close="fileReferenceToEdit = null"
    >
      <template #default>
        <file-meta-editor
            :tags="tags"
            :directories="directories"
            v-model="fileReferenceToEdit"
            @create-tag="createTag"
            @create-directory="createDirectory"
        />
      </template>
      <template #footer>
        <span v-if="fileReferenceToEdit" @click.stop class="written-delete-span">
            <confirmed-click-button
                btn-class="btn btn-outline-danger btn-sm no-background written-delete"
                :modal-title="$t('files.safe_delete.title', {filename: fileReferenceToEdit.title})"
                :modal-body="$t('files.safe_delete.body')"
                :modal-yes="$t('files.safe_delete.yes')"
                :modal-no="$t('files.safe_delete.no')"
                no-button-class="btn btn-light justify-self-start"
                @click="tryDeleteFileReference(fileReferenceToEdit)"
            >

              <i class="fa-solid fa-trash" aria-hidden="true"></i>
              {{ $t('directory_control.safe_delete.yes') }}
            </confirmed-click-button>
        </span>
        <button type="button" class="btn btn-light" @click="fileReferenceToEdit = null">
          {{ $t('files.edit.cancel_button') }}
        </button>
        <button type="button" class="btn btn-primary" @click="tryEditFileReference(fileReferenceToEdit)">
          {{ $t('files.edit.save_button') }}
        </button>
      </template>
    </general-modal>

    <div class="loading-modal" v-if="loading">
      <loading-screen/>
    </div>
  </div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'

import { mapActions, mapState } from 'vuex'

import SearchBar from '@/components/SearchBar'
import FileDirectoryMenu from '@/components/Files/FileDirectoryMenu'
import MultiFileUpload from '@/components/Files/MultiFileUpload'
import SvgIcon from '@/components/SvgIcon'
import DropDown from '@/components/DropDown'
import MultiLayoutList from '@/components/MultiLayoutList'
import FileMetaEditor from '@/components/Files/FileMetaEditor'
import FormCheckbox from '@pixelstein/ps-form/components/PsFormCheckbox'
import GeneralModal from 'pixelstein-vue-app-package/src/vue2/PsModal/PsModalGeneralModal'
import ConfirmedClickButton from 'pixelstein-vue-app-package/src/vue2/PsModal/PsModalButtonConfirmationModal'

import GeneralCard from '@/components/GeneralCard'
import GeneralListItem from '@/components/GeneralListItem'

import FileTypes from '@/mixins/file-types'
import ViewDataCache from '@/mixins/view-data-cache'
import TagFilter from '@/components/TagFilter'

import { ACTION_CREATE, ACTION_UPDATE, hasPermission, ACTION_DELETE } from '@/utils/permissions.js'
import addFileThumbnail from '@/assets/defaultThumbnails/add_file.svg'
import LoadingScreen from '@/components/LoadingScreen.vue'

export default {
  name: 'FilesModule',
  mixins: [FileTypes, ViewDataCache],
  components: {
    TagFilter,
    FileMetaEditor,
    MultiLayoutList,
    FormCheckbox,
    DropDown,
    SvgIcon,
    FileDirectoryMenu,
    SearchBar,
    MultiFileUpload,
    GeneralModal,
    ConfirmedClickButton,
    GeneralCard,
    GeneralListItem,
    LoadingScreen
  },
  data () {
    return {
      lock: true,
      searchQuery: '',
      lastSearchQuery: '',
      loading: true,
      fileReferenceToEdit: null,
      currentlyLoadedFileName: '',
      activeTags: {},
      selectedDirectoryId: '',
      open: false,
      pageLoading: false,
      page: 1,
      itemsPerPage: 20,
      scrollPos: 0,
      addFileThumbnail,
      instanceLimits: null
    }
  },
  computed: {
    ...mapState({
      directories: state => state.Api.Directories.all,
      tags: state => state.Api.Tags.all,
      fileReferences: state => state.Api.FileReferences.all,
      user: state => state.user,
      limits: state => state.Api.Instance,
      authToken: state => state.Api.authToken,
    }),
    cacheableKeys () {
      return ['activeTags', 'searchQuery', 'selectedDirectoryId']
    },
    maxUploadFileSize() {
      return this.instanceLimits?.max_upload_file_size ?? this.$config.UPLOAD_MAX_FILE_SIZE
    },
    maxUploadsPerRequest() {
      return this.instanceLimits?.max_uploads_per_request ?? this.$config.MAX_UPLOADS_PER_REQUEST
    },
    fileReferencesOfSelectedDirectory () {
      let fileReferences = _cloneDeep(this.fileReferences)
          .filter((file, idx, array) => idx === array.findIndex(f => f.id === file.id))
          .map(fileRef => {
            if (fileRef.file) {
              fileRef.title = fileRef.name
            }
            return fileRef
          })

      if (this.selectedDirectoryId) {
        fileReferences = fileReferences.filter(fr => fr.directories.find(d => d.id === this.selectedDirectoryId))
      }

      return fileReferences
    },
    fileTypes () {
      return [...new Set(this.fileReferencesOfSelectedDirectory.map(file => file?.file?.type))]
    },
    filteredGroupedFileTypes () {
      return this.groupedFileTypes.filter(group => group.mimeTypes.some(mime => this.fileTypes.find(m => m === mime)))
    },
    sortedFilteredGroupedFileTypes () {
      return this.filteredGroupedFileTypes
          .slice()
          .sort((a, b) => a.title.localeCompare(b.title))
    },
    activeFileTypes () {
      return this.filteredGroupedFileTypes
          .filter(g => g.active)
          .flatMap(g => g.mimeTypes)
    },
    filteredFileReferences () {
      if (this.searchQuery !== this.lastSearchQuery) {
        this.loading = true
      }
      const regex = new RegExp(this.searchQuery, 'i')
      this.lastSearchQuery = this.searchQuery;

      const filteredFiles = this.fileReferencesOfSelectedDirectory
          .filter(file => !!file?.title?.match(regex) || !!file?.name?.match(regex))
          .filter(file => this.activeFileTypes.length === 0 || !!this.activeFileTypes.find(mime => file?.file?.type === mime))
      setTimeout(() => this.loading = false, 1000)
      return filteredFiles
    },
    tagFilteredFileReferences () {
      const tagsToFilter = this.tagsOfSelectedDirectory.filter(tag => !!this.activeTags[tag.id])

      if (tagsToFilter.length === 0) {
        return this.filteredFileReferences
      }

      // separate computed to prevent "too much recursion" issue
      return this.filteredFileReferences
          .filter(fileReference => {
            return fileReference?.tags.length > 0
                && tagsToFilter.every(tag => fileReference?.tags.find(t => t.id === tag.id))
          })
    },
    mappedFileReferences () {
      return this.tagFilteredFileReferences.map(fileReference => {
        fileReference = _cloneDeep(fileReference) // prevents directly update of the stored state object

        if (fileReference.file && !fileReference.default_thumbnail) {
          fileReference.default_thumbnail = this.getDefaultThumbnail(fileReference.file)
        }

        return fileReference
      })
    },
    itemOptions () {
      const options = [
        {
          group: 'default',
          groupLabel: 'default',
          value: 'open',
          label: this.$t('files.file_toolbar.open_file'),
          active: false,
        },
      ]

      if (hasPermission(this.user, 'FileReferences', ACTION_UPDATE)) {
        options.push(
            {
              group: 'default',
              groupLabel: 'default',
              value: 'edit',
              label: this.$t('files.file_toolbar.edit_file'),
              active: false,
            },
        )
      }

      return options
    },
    selectedDirectory () {
      return this.directories?.find(g => g.id === this.selectedDirectoryId)
    },
    tagsOfSelectedDirectory () {
      return this.filteredFileReferences
          .flatMap(fileReference => fileReference.tags)
          .filter((tag, index, array) => index === array.findIndex(t => t.id === tag.id))
    },
    filteredTagsOfSelectedDirectory () {
      const tagsUsedInFiles = this.tagFilteredFileReferences
          .flatMap(fileReference => fileReference.tags)
          .filter((tag, index, array) => index === array.findIndex(t => t.id === tag.id))

      return this.tagsOfSelectedDirectory
          .filter(tag => !!tagsUsedInFiles.find(t => t.id === tag.id))
    },
    canUploadFiles () {
      return !!hasPermission(this.user, 'Files', ACTION_CREATE)
          && !!hasPermission(this.user, 'FileReferences', ACTION_CREATE)
    },
    canEditDirectories () {
      return !!hasPermission(this.user, 'Directories', ACTION_UPDATE)
          && !!hasPermission(this.user, 'Directories', ACTION_DELETE)
    },
  },
  watch: {
    activeTags: {
      deep: true,
      handler () {
        this.resetPagination()
      },
    },
    selectedDirectoryId () {
      this.resetPagination()
    },
    groupedFileTypes: {
      deep: true,
      handler () {
        this.resetPagination()
      },
    },
  },
  methods: {
    ...mapActions({
      addDirectory: 'Api/Directories/add',
      editDirectory: 'Api/Directories/edit',
      deleteDirectory: 'Api/Directories/delete',
      getFileReferences: 'Api/FileReferences/index',
      addFileReference: 'Api/FileReferences/add',
      editFileReference: 'Api/FileReferences/edit',
      deleteFileReference: 'Api/FileReferences/delete',
      purgeFileReference: 'Api/FileReferences/purge',
      addFile: 'Api/Files/add',
      editFile: 'Api/Files/edit',
      deleteFile: 'Api/Files/delete',
      addTag: 'Api/Tags/add',
      getInstanceLimits: 'Api/Instance/limits',
    }),
    resetPagination () {
      if (this.tagFilteredFileReferences) {
        this.$refs.filelist.$refs.scroll_wrap.scroll({ top: 0 })
        this.scrollPos = 0
        this.page = 1
      }
    },
    async fetchLimits () {
      try {
        this.instanceLimits = await this.getInstanceLimits()
      } catch (e) {
        this.apiErrorHandler(e)
      }
    },
    updateDirectories ({ directoriesToUpdate, directoriesToDelete, directoriesToAdd, onSuccessCallback }) {
      Promise.all([
        directoriesToUpdate.map(dir => this.editDirectory(dir).catch(this.apiErrorHandler)),
        directoriesToDelete.map(dir => this.deleteDirectory(dir).catch(this.apiErrorHandler)),
        directoriesToAdd.map(dir => this.addDirectory(dir).catch(this.apiErrorHandler)),
      ])
          .then(onSuccessCallback)
    },
    createDirectory (options) {
      this.addDirectory({
        name: options.name,
      })
          .then(options.onSuccessCallback)
          .catch(this.apiErrorHandler)
          .finally(options.onFinallyCallback)
    },
    tagsForFile (file) {
      return this.fileReferences.find(fr => fr.file_id === file?.id)?.tags || []
    },
    handleItemOption (action, fileReference) {
      switch (action.value) {
        case 'edit':
          if (!hasPermission(this.user, 'FileReferences', ACTION_UPDATE)) {
            break
          }

          this.fileReferenceToEdit = _cloneDeep(fileReference)
          this.fileReferenceToEdit.original_name = this.fileReferenceToEdit.name // name copy to display in modal title
          break
        case 'open':
          this.$router.push('/files/' + fileReference.file.id).catch(() => null)
          break
      }
    },
    setMimeGroupActive (group, active) {
      this.groupedFileTypes.find(g => g.title === group.title).active = active
    },
    updateActiveTags (tags) {
      const tagEntries = tags.map(({ id }) => [id, true])

      this.activeTags = Object.fromEntries(tagEntries)
    },
    async uploadFile (options) {
      if (
          !hasPermission(this.user, 'FileReferences', ACTION_CREATE)
          || !hasPermission(this.user, 'Files', ACTION_CREATE)
      ) {
        return
      }

      for (let idx = 0; idx < options.files.length; idx++) {
        try {
          const file = options.files[idx]

          const fileResult = await this.addFile({
            file: file.file,
            title: file.fileReference.name,
            $uploadProgressCallback: p => {
              options.onProgress(idx, (p.loaded / p.total * 100))
            },
          })

          await this.addFileReference({
            contain: ['tags', 'directories'],
            file_id: fileResult.id,
            ...file.fileReference,
          })

          options.onSuccessCallback(idx)

          await this.getFileReferences({
            contain: ['directories', 'files', 'tags']
          });
        } catch (e) {
          options.onErrorCallback(idx)
          this.apiErrorHandler(e)
        }
      }

      options.onFinallyCallback()
    },
    async tryEditFileReference (fileReference) {
      try {
        await this.editFileReference({ ...fileReference, $merge: false })
        this.fileReferenceToEdit = null
      } catch (e) {
        return this.apiErrorHandler(e)
      }
    },
    async tryDeleteFileReference (fileReference) {
      try {
        await this.deleteFileReference(fileReference)
        this.fileReferenceToEdit = null
      } catch (e) {
        this.apiErrorHandler(e)
      }
    },
    createTag (options) {
      this.addTag({
        name: options.name,
      })
          .then(options.onSuccessCallback)
          .catch(this.apiErrorHandler)
          .finally(options.onFinallyCallback)
    },
  },
  async mounted () {
    if (this.tagFilteredFileReferences) {
      this.$nextTick(() => this.$refs.filelist.$refs.scroll_wrap.scroll({
        top: this.scrollPos,
      }))
    }

    if (this.authToken) {
      await this.fetchLimits()
    }

    this.$store.subscribe((mutation) => {
      if (mutation.type === 'Api/setAuthToken') {
        if (mutation.payload !== null) {
          this.fetchLimits()
        }
      }
    })
  },
}
</script>
