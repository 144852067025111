'use strict'

export default {
  methods: {
    targetUrlForRoute(route) {
      const targetPath = this.$router.resolve(route)
        .href
        .replaceAll('//', '/')
      let target
      
      if (this.$config.FRONTEND_BASE_URL.endsWith('/') && targetPath.startsWith('/')) {
        target = this.$config.FRONTEND_BASE_URL + targetPath.substring(1)
      } else if (!this.$config.FRONTEND_BASE_URL.endsWith('/') && !targetPath.startsWith('/')) {
        target = this.$config.FRONTEND_BASE_URL + '/' + targetPath
      } else {
        target = this.$config.FRONTEND_BASE_URL + targetPath
      }
      
      return target
    }
  }
}
