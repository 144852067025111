<template>
  <div class="dashboard-module view">
    <scroll-container class="col" offset-to=".dashboard-module">
      <template #toolbar>
        <div class="row align-items-center">
          <div class="col">
            <div class="px-3 mx-n3 pb-1 pt-2 d-flex justify-content-between">
              <router-link-back to="/dashboards">
                {{ dashboard?.name }}
              </router-link-back>
            </div>
          </div>
          <div class="col text-right" v-if="dashboard">
            <button class="btn btn-primary" @click="onDownload">{{ $t('dashboards.download') }}</button>
            &nbsp;
            <button class="btn btn-primary" @click="editDashboard">{{ $t('dashboards.edit_dashboard') }}</button>
          </div>
        </div>
      </template>

      <div class="row bg-white" ref="dashboard">
        <div class="col-12 col-lg-6" v-for="chart in orderedCharts ?? []">
          <CachedStatisticsChart
              v-if="chart.chart_cache"
              :statistics="chart.chart_cache"
              :chart-config="chart"
              :report-fields="reportFields(chart)"
              :dataset-id-key="labelForField(chart, chart?.config?.chart_value)"
              :chart-type="chart.type"
          />
          <p v-else class="text-danger">Missing cache for {{ chart.id }}</p>
        </div>
      </div>
    </scroll-container>
    <div class="loading-modal" v-if="loading">
      <loading-screen/>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import RouterLinkBack from '@/components/RouterLinkBack.vue'
import { mapActions } from 'vuex'
import StatisticChart from '@/components/Statistics/StatisticsChart.vue'
import CachedStatisticsChart from '@/components/Statistics/CachedStatisticsChart.vue'
import ScrollContainer from '@/components/ScrollContainer.vue'
import { saveAs } from 'file-saver'
import LoadingScreen from '@/components/LoadingScreen.vue'
import { flattenFields } from '@pixelstein/ps-form/utils/reportConfig.js'

export default defineComponent({
  name: 'DashboardView',
  components: { LoadingScreen, StatisticChart, RouterLinkBack, ScrollContainer, CachedStatisticsChart },
  data () {
    return {
      dashboard: null,
      loading: false,
    }
  },
  props: {
    dashboardId: String,
  },
  computed: {
    chartOrder () {
      return this.dashboard?.config?.order ?? []
    },
    orderedCharts () {
      return (this.dashboard?.charts ?? [])
          ?.toSorted((a, b) => this.chartOrder.findIndex(i => i === a.id) - this.chartOrder.findIndex(i => i === b.id))
    },
  },
  methods: {
    ...mapActions({ loadDashboard: 'Api/ChartCollections/view' }),
    reportFields (chart) {

      return chart.report_configs
          .flatMap(rc => flattenFields(rc.fields))
          .filter((field, idx, array) => array.findIndex(
              f => (f?.options?.name || f?.options?.key) === (field?.options?.name || f?.options?.key)) === idx)
    },
    labelForField (chart, key) {
      return this.reportFields(chart).find(field => field?.options?.key === key)?.options?.label
    },
    async onDownload () {
      this.loading = true
      const dashboard = await this.loadDashboard({
        id: this.dashboardId,
        type: 'xlsx',
      })

      saveAs(dashboard, this.dashboard.name + '.xlsx')
      setTimeout(() => this.loading = false, 1000)
    },
    editDashboard () {
      this.$router.push(['/dashboards', this.dashboardId, 'edit'].join('/')).catch(() => null)
    },
  },
  async mounted () {
    try {
      const handle = setTimeout(() => this.loading = true, 250)

      this.dashboard = await this.loadDashboard({
        id: this.dashboardId,
        type: 'json',
        contain: [
          'charts',
          'charts.report_configs',
          'charts.chart_caches',
          'charts.chart_caches.datasets',
        ],
      })

      clearTimeout(handle)
    } finally {
      this.loading = false
    }
  },
})
</script>
