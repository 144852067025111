import {
  fieldsWithType,
} from 'paperclip-lib/src/report-configs/ReportConfigUtils'

/**
 * find subtype of content blocks
 * @param fields
 * @param inputType
 *
 * @returns {Object[]}
 */
export function findKeysForSubtype (fields, inputType) {
  return fieldsWithType(fields, 'input', inputType)
    .map(field => field?.options?.name)
    .filter(name => !!name)
}

export function collectDateKeys (fields) {
  return findKeysForSubtype(fields, 'datetime-local')
}