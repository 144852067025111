<template>
  <div>
    <p>
      {{$t('help.device_management.description')}}
    </p>
  </div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon.vue'
export default {
  name: 'DeviceManagementHelp',
  components: { SvgIcon },
  data() {
    return {
      openParagraph: "",
    }
  },
  methods: {
    setParagraph(string) {
      this.openSubtypes = false
      this.openParagraph === string ? this.openParagraph = '' : this.openParagraph = string
    }
  }
}
</script>