<template>
  <div>
    <p>
      {{$t('help.reports.description')}}
    </p>
    <p>
      {{$t('help.reports.overview')}}
    </p>
    <div class="mt-4 mb-2 menu" :class="{active: openParagraph === 'create_report'}" @click="setParagraph('create_report')">
      <svg-icon name="arrow_right"/><h5 class="d-inline mx-2"><b>{{$t('help.reports.create_report.title')}}</b></h5>
    </div>
    <div class="menu-item" :class="{active: openParagraph === 'create_report'}">
      <ul>
        <li>{{$t('help.reports.create_report.tab')}}</li>
        <li>{{$t('help.reports.create_report.overview')}}</li>
        <li>{{$t('help.reports.create_report.new')}}</li>
        <li>{{$t('help.reports.create_report.edit_example')}}</li>
        <ul>
          <li>{{$t('help.reports.create_report.pop_up')}}</li>
          <li>{{$t('help.reports.create_report.drag_and_drop')}}</li>
        </ul>
      </ul>
    </div>

    <div class="mt-4 mb-2 menu" :class="{active: openParagraph === 'settings'}" @click="setParagraph('settings')">
      <svg-icon name="arrow_right"/><h5 class="d-inline mx-2"><b>{{$t('help.reports.settings.title')}}</b></h5>
    </div>
    <div class="menu-item" :class="{active: openParagraph === 'settings'}">
      <ul>
        <li>{{$t('help.reports.settings.name')}}</li>
        <li>{{$t('help.reports.settings.condition.description')}}</li>
        <ul>
          <li>{{$t('help.reports.settings.condition.add_condition')}}</li>
          <li>{{$t('help.reports.settings.condition.key')}}</li>
          <li>{{$t('help.reports.settings.condition.label')}}</li>
          <li>{{$t('help.reports.settings.condition.change')}}</li>
        </ul>
        <li>{{$t('help.reports.settings.fields')}}</li>
        <li>{{$t('help.reports.settings.database')}}</li>
        <li>{{$t('help.reports.settings.save_and_delete')}}</li>
      </ul>
    </div>

    <div class="mt-4 mb-2 menu" :class="{active: openParagraph === 'create_content'}" @click="setParagraph('create_content')">
      <svg-icon name="arrow_right"/><h5 class="d-inline mx-2"><b>{{$t('help.reports.create_content.title')}}</b></h5>
    </div>
    <div class="menu-item" :class="{active: openParagraph === 'create_content'}">
      <p>
        {{$t('help.reports.create_content.options')}}
      </p>
      <ul>
        <li>
          <b>{{$t('help.reports.create_content.input.title')}}</b>{{$t('help.reports.create_content.input.description')}}
          <div class="w-100 my-3 pl-2 menu" @click="openSubtypes = !openSubtypes"><svg-icon name="arrow_right" :class="{active: openSubtypes}"/><span class="ml-2">{{ openSubtypes ? $t('help.reports.create_content.input.hide_subtypes') : $t('help.reports.create_content.input.show_subtypes')}}</span></div>
          <div class="pl-3 subtypes" :class="{active: openSubtypes}">
            <table>
              <tr>
                <td>
                  <div class="row">
                    <div v-for="content in createContent" class="col col-12 col-md-6 col-lg-4 my-0 py-1 highlight" :class="{active: currentContent === content}" @click="currentContent = content">
                      <b>{{content.title}}</b>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="d-block">
                  <p><b>{{currentContent.title}}</b></p>
                  <p>{{currentContent.description}}</p>
                </td>
              </tr>
            </table>
          </div>
          <ul class="pl-4">
            <li>{{$t('help.reports.create_content.input.key_and_label')}}</li>
            <li>{{$t('help.reports.create_content.input.further_options')}}</li>
            <li>{{$t('help.reports.create_content.input.required')}}</li>
            <li>{{$t('help.reports.create_content.input.keep_value')}}</li>
          </ul>
        </li>
        <li>
          <b>{{$t('help.reports.create_content.canvas.title')}}</b>{{$t('help.reports.create_content.canvas.description')}}
          <div>
            <p>{{$t('help.reports.create_content.canvas.inputs')}}</p>
          </div>
        </li>
        <li>
          <b>{{$t('help.reports.create_content.text.title')}}</b>{{$t('help.reports.create_content.text.description')}}
          <div>
            <p>{{$t('help.reports.create_content.text.inputs')}}</p>
          </div>
        </li>
        <li><b>{{$t('help.reports.create_content.image.title')}}</b>{{$t('help.reports.create_content.image.description')}}</li>
        <li><b>{{$t('help.reports.create_content.container.title')}}</b>{{$t('help.reports.create_content.container.description')}}</li>
        <li><b>{{$t('help.reports.create_content.accordeon.title')}}</b>{{$t('help.reports.create_content.accordeon.description')}}</li>
      </ul>
    </div>

    <div class="mt-4 mb-2 menu" :class="{active: openParagraph === 'layout'}" @click="setParagraph('layout')">
      <svg-icon name="arrow_right"/><h5 class="d-inline mx-2"><b>{{$t('help.reports.layout.title')}}</b></h5>
    </div>
    <div class="menu-item" :class="{active: openParagraph === 'layout'}">
      <p>
        {{$t('help.reports.layout.description')}}
      </p>
      <ul>
        <li>
          {{$t('help.reports.layout.sizes.description')}}
          <div class="w-100 mt-3 mb-1 pl-2 menu" @click="openSubtypes = !openSubtypes"><svg-icon name="arrow_right" :class="{active: openSubtypes}"/><span class="ml-2">{{ openSubtypes ? $t('help.reports.layout.sizes.hide_sizes') : $t('help.reports.layout.sizes.show_sizes')}}</span></div>
        </li>
        <div class="pl-3 subtypes" :class="{active: openSubtypes}">
          <table>
            <tr>
              <td>
                <div class="row">
                  <div v-for="layout in layouts" class="col col-12 col-md-6 col-lg-4 my-0 py-1 highlight" :class="{active: currentLayout === layout}" @click="currentLayout = layout">
                    <b>{{layout.title}}</b>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td class="d-block">
                <p><b>{{currentLayout.title}}</b></p>
                <p>{{currentLayout.description}}<br>&nbsp;</p>
                <p v-if="currentLayout.examples?.length !== 0">{{$t('help.reports.layout.sizes.examples')}}</p>
                <div v-for="example in currentLayout.examples"><i>{{example}}</i></div>
              </td>
            </tr>
          </table>
        </div>
        <li>{{$t('help.reports.layout.preview')}}</li>
        <li>{{$t('help.reports.layout.edit')}}</li>
      </ul>
    </div>

    <div class="mt-4 mb-2 menu" :class="{active: openParagraph === 'read'}" @click="setParagraph('read')">
      <svg-icon name="arrow_right"/><h5 class="d-inline mx-2"><b>{{$t('help.reports.read.title')}}</b></h5>
    </div>
    <div class="menu-item" :class="{active: openParagraph === 'read'}">
      <ul>
        <li>{{$t('help.reports.read.overview')}}</li>
        <li>{{$t('help.reports.read.list')}}</li>
        <li>{{$t('help.reports.read.edit')}}</li>
        <li>{{$t('help.reports.read.download')}}</li>
        <li>{{$t('help.reports.read.statistics')}}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon.vue'
export default {
  name: 'ReportsHelp',
  components: { SvgIcon },
  data() {
    return {
      openParagraph: "",
      openSubtypes: false,
      currentContent: {},
      currentLayout: {},
      createContent: [
        {title: this.$t('help.reports.create_content.input.text.title'), description: this.$t('help.reports.create_content.input.text.description')},
        {title: this.$t('help.reports.create_content.input.mail.title'), description: this.$t('help.reports.create_content.input.mail.description')},
        {title: this.$t('help.reports.create_content.input.date.title'), description: this.$t('help.reports.create_content.input.date.description')},
        {title: this.$t('help.reports.create_content.input.date_and_hour.title'), description: this.$t('help.reports.create_content.input.date_and_hour.description')},
        {title: this.$t('help.reports.create_content.input.number.title'), description: this.$t('help.reports.create_content.input.number.description')},
        {title: this.$t('help.reports.create_content.input.link.title'), description: this.$t('help.reports.create_content.input.link.description')},
        {title: this.$t('help.reports.create_content.input.phone.title'), description: this.$t('help.reports.create_content.input.phone.description')},
        {title: this.$t('help.reports.create_content.input.switch.title'), description: this.$t('help.reports.create_content.input.switch.description')},
        {title: this.$t('help.reports.create_content.input.checkbox.title'), description: this.$t('help.reports.create_content.input.checkbox.description')},
        {title: this.$t('help.reports.create_content.input.radio.title'), description: this.$t('help.reports.create_content.input.radio.description')},
        {title: this.$t('help.reports.create_content.input.dropdown.title'), description: this.$t('help.reports.create_content.input.dropdown.description')},
        {title: this.$t('help.reports.create_content.input.formatted.title'), description: this.$t('help.reports.create_content.input.formatted.description')},
        {title: this.$t('help.reports.create_content.input.multi_line.title'), description: this.$t('help.reports.create_content.input.multi_line.description')},
        {title: this.$t('help.reports.create_content.input.data.title'), description: this.$t('help.reports.create_content.input.data.description')},
        {title: this.$t('help.reports.create_content.input.hidden.title'), description: this.$t('help.reports.create_content.input.hidden.description')},
      ],
      layouts: [
        {title: this.$t('help.reports.layout.sizes.quarter.title'), description: this.$t('help.reports.layout.sizes.quarter.description'), examples: ['1/4, 1/4, 1/4, 1/4', '1/4, 3/4']},
        {title: this.$t('help.reports.layout.sizes.third.title'), description: this.$t('help.reports.layout.sizes.third.description'), examples: ['1/3, 1/3, 1/3', '1/3, 2/3']},
        {title: this.$t('help.reports.layout.sizes.half.title'), description: this.$t('help.reports.layout.sizes.half.description'), examples: ['1/2, 1/2', '1/2, 1/4, 1/4']},
        {title: this.$t('help.reports.layout.sizes.two_third.title'), description: this.$t('help.reports.layout.sizes.two_third.description'), examples: ['2/3, 1/3']},
        {title: this.$t('help.reports.layout.sizes.three_quarters.title'), description: this.$t('help.reports.layout.sizes.three_quarters.description'), examples: ['1/4, 3/4']},
        {title: this.$t('help.reports.layout.sizes.full.title'), description: this.$t('help.reports.layout.sizes.full.description'), examples: []},
      ]
    }
  },
  methods: {
    setParagraph(string) {
      this.openSubtypes = false
      this.openParagraph === string ? this.openParagraph = '' : this.openParagraph = string
    }
  },
  mounted () {
    this.currentContent = this.createContent[0]
    this.currentLayout = this.layouts[0]
  }
}
</script>